import axios from 'axios'
import { uniqBy } from 'lodash-es'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import NewPlanOptions from '@/lib/NewPlanOptions'
import { duplicateActivityAnalysis } from '@/services/operating-model-api.service.js'

export const getBoards = async ({ userId, forceTokenRefresh = true }) => {
  if (!userId) {
    console.error('userId is undefined')
    return null
  }

  const ret = await axios.get(`${API_URL}/boards`, {
    headers: {
      ...(await getAuthHeader(forceTokenRefresh))
    }
  })

  if (ret.status === 200) {
    const boards = []

    ret.data.forEach((data) => {
      if (!data.companyId || data.companyId === 'undefined' || data.companyId === '') {
        data.companyName = null
        data.companyId = null
      }

      if (!data.accessLevels) {
        data.accessLevels = []
      }

      const uniqAccessLevels = uniqBy(data.accessLevels, 'uid')
      if (uniqAccessLevels.length !== data.accessLevels.length) {
        data.accessLevels = uniqAccessLevels
      }

      boards.push(data)
    })

    return boards
  } else throw new Error(ret.statusText)
}

export const getBoard = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/boards/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getBoardMapping = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/boards/${boardId}/board-mapping`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const updateBoard = async ({ boardId, payload }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/boards/${boardId}`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Update the access level for a user on a specific board.
 *
 * @param {Object} options - An object containing the parameters for the update.
 * @param {string} options.targetUid - The unique identifier of the target user.
 * @param {string} options.newAccessLevel - The new access level to assign to the user.
 * @param {string} options.boardId - The unique identifier of the board to update.
 */
export const updateAccessLevel = async ({ targetUid, newAccessLevel, boardId }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/boards/${boardId}/updateAccessLevel`,
      { data: { targetUid, newAccessLevel } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 200) {
      return ret.data
    } else {
      throw new Error(ret.statusText)
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const submitBoard = async ({ boardId, payload }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/boards/${boardId}/submit`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const unsubmitBoard = async ({ boardId, payload }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/boards/${boardId}/unsubmit`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const createBoard = async ({ payload }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/boards`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const cloneInstance = async ({ payload }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/boards/${payload.srcBoardId}/clone-instance`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const deleteBoard = async ({ boardId }) => {
  try {
    const ret = await axios.delete(`${API_URL}/boards/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      // Return number of boards rows deleted
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Call backend to clone a board
 *
 * @param newBoardId (Required) the new board ID
 * @param srcBoardId (Required) the target board to clone
 * @param newBoardName (Required) the name of the new board
 * @param newBoardDescription the description of the new board
 * @param boardProperties Additional board properties for the new board
 * @param filter to apply to the new board i.e { name: "department", value: "Product"}
 * @param planType one of the entry from @/lib/NewPlanOptions - If duplicating an existing plan, planType must be left empty ''
 * @returns {Promise} Return the boardId of the cloned board
 */
export const cloneBoard = async ({
  newBoardId,
  srcBoardId,
  newBoardName,
  newBoardDescription,
  planType = '',
  boardProperties,
  filter,
  mainBoardId
}) => {
  const payload = {
    newBoardId,
    srcBoardId,
    newBoardName,
    newBoardDescription,
    planType,
    boardProperties,
    filter
  }

  try {
    const ret = await axios.post(
      `${API_URL}/boards/${srcBoardId}/clone`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    duplicateActivityAnalysis({
      boardId: mainBoardId,
      planId: srcBoardId,
      newPlanId: newBoardId
    })

    if (ret.status === 201) {
      return ret.data.boardId
    } else throw new Error(ret.statusText)
  } catch (error) {
    if (error.response?.status !== 404) throw error.response.data
    else throw new Error('Something went wrong while creating the plan')
  }
}

/**
 * Call backend to clone a board
 *
 * @param newBoardId (Required) the new board ID
 * @param srcBoardId (Required) the target board to clone
 * @param newBoardName (Required) the name of the new board
 * @param newBoardDescription the description of the new board
 * @param boardProperties Additional board properties for the new board
 * @param filters to apply to the new board i.e { name: "department", value: "Product"}
 * @param planType one of the entry from @/lib/NewPlanOptions
 * @returns {Promise} Return the boardId of the cloned board
 */
export const cloneBoardWithFilters = async ({
  newBoardId,
  srcBoardId,
  newBoardName,
  filters,
  boardProperties
}) => {
  const payload = {
    newBoardId,
    srcBoardId,
    newBoardName,
    planType: NewPlanOptions.Filter,
    boardProperties,
    filters
  }

  try {
    const ret = await axios.post(
      `${API_URL}/boards/${srcBoardId}/clone`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 201) {
      return ret.data.boardId
    } else throw new Error(ret.statusText)
  } catch (error) {
    if (error.response?.status !== 404) throw error.response.data
    else throw new Error('Something went wrong while creating the plan')
  }
}

/**
 * Call backend to clone a board
 *
 * @param newBoardId (Required) the new board ID
 * @param srcBoardId (Required) the target board to clone
 * @param newBoardName (Required) the name of the new board
 * @param newBoardDescription the description of the new board
 * @param boardProperties Additional board properties for the new board
 * @param filters to apply to the new board i.e { name: "department", value: "Product"}
 * @param planType one of the entry from @/lib/NewPlanOptions
 * @returns {Promise} Return the boardId of the cloned board
 */
export const editBoard = async ({ newBoardId, srcBoardId, planType = '', projectId = null }) => {
  const payload = {
    newBoardId,
    srcBoardId,
    planType,
    boardProperties: {
      projectId
    }
  }

  try {
    const ret = await axios.post(
      `${API_URL}/boards/${srcBoardId}/clone`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 201) {
      return ret.data.boardId
    } else throw new Error(ret.statusText)
  } catch (error) {
    if (error.response?.status !== 404) throw error.response.data
    else throw new Error('Something went wrong while creating the plan')
  }
}

/**
 * Call backend to clone a board
 *
 * @param newBoardId (Required) the new board ID
 * @param srcBoardId (Required) the target board to clone
 * @param filter to apply to the new board i.e { name: "department", value: "Product"}
 * @returns {Promise} Return the boardId of the cloned board
 */
export const editBoardWithFilters = async ({
  newBoardId,
  srcBoardId,
  filters,
  projectId = null
}) => {
  const payload = {
    newBoardId,
    srcBoardId,
    planType: NewPlanOptions.Filter,
    filters,
    boardProperties: {
      projectId
    }
  }

  try {
    const ret = await axios.post(
      `${API_URL}/boards/${srcBoardId}/clone`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 201) {
      return ret.data.boardId
    } else throw new Error(ret.statusText)
  } catch (error) {
    if (error.response?.status !== 404) throw error.response.data
    else throw new Error('Something went wrong while creating the plan')
  }
}

/**
 * Call backend to re-sync the dest board with the src board
 * The backend will maintain the nodes that have scenarioMetaData
 *
 * @param srcBoardId (Required) The src board. Typically the main board
 * @param destBoardId (Required) the destination board. Typically a plan.
 * @returns {Promise} Return the status
 */
export const resync = async ({
  srcBoardId,
  destBoardId,
  newBoardId,
  newBoardName,
  filters,
  planType
}) => {
  const payload = {
    newBoardId,
    newBoardName,
    filters,
    planType,
    mainBoardId: srcBoardId
  }

  try {
    const ret = await axios.post(
      `${API_URL}/boards/${destBoardId}/resync`,
      { data: { payload } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )
    if (ret.status === 201 || ret.status === 200) {
      return ret.data
    } else {
      console.log(ret)
      throw new Error(ret.statusText)
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Call backend to sync the new board with conflict resolution
 *
 * @param boardId (Required) The new board id
 * @returns {Promise} Return the status
 */
export const completeResync = async ({
  boardId,
  selectedPeopleFromOldScenario,
  selectedConflictFields,
  oldBoardId
}) => {
  const payload = {
    selectedPeopleFromOldScenario,
    selectedConflictFields,
    oldBoardId
  }

  try {
    const ret = await axios.post(
      `${API_URL}/boards/${boardId}/complete-resync`,
      { data: { payload } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )
    if (ret.status === 201 || ret.status === 200) {
      return ret.data
    } else {
      console.log(ret)
      throw new Error(ret.statusText)
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Retrieves the board history from the server.
 *
 * @param {Object} options - The options for retrieving the board history.
 * @param {string} options.boardId - The ID of the board.
 * @param {number} options.startIndex - The start index of the history.
 * @param {number} options.max - The maximum number of history entries to retrieve.
 * @returns {Promise<Object|null>} - A promise that resolves to the board history data or null if an error occurs.
 */
export async function getBoardHistory({ boardId, personId }) {
  try {
    const url = personId
      ? `${API_URL}/boards/${boardId}/history/${personId}`
      : `${API_URL}/boards/${boardId}/history`

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getBoardOwnerEmail = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/boards/${boardId}/owner-email`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
