<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox
    v-slot="{ open }"
    v-model="selected"
    as="div"
    class="selectMenu"
    :by="compareBy || undefined"
    :disabled="disabled"
    :multiple="allowMultipleSelection"
  >
    <ListboxLabel v-if="label" class="mb-1 mt-2 block text-sm font-medium text-gray-700">
      {{ label }}
    </ListboxLabel>
    <div class="relative font-inter">
      <ListboxButton
        tabindex="-1"
        class="relative flex h-11 w-full cursor-default items-center rounded-md border bg-white py-2 pl-3 pr-10 text-left text-base font-normal text-gray-500 shadow-sm focus:border-bluedark-300 focus:outline-none focus:ring focus:ring-bluedark-100/50"
        :class="[
          open ? 'border-bluedark-300 ring ring-bluedark-100/50' : 'border-gray-300 ring-0',
          disabled ? 'cursor-not-allowed bg-gray-50' : 'bg-white'
        ]"
        @click="clearSearchQuery"
      >
        <div v-if="icon" class="mr-2 flex h-5 w-[1.5rem] items-center justify-center">
          <component :is="icon.name" />
        </div>
        <div class="block truncate">
          <span
            v-if="allowMultipleSelection ? selected.length > 0 : selected.name"
            class="text-gray-900"
          >
            {{ allowMultipleSelection ? selected.map((s) => s.name).join(', ') : selected.name }}
          </span>
          <span v-else class="text-gray-500">{{ label || placeholder }}</span>
        </div>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            v-if="!open"
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          ></ChevronDownIcon>
          <ChevronUpIcon v-else class="h-5 w-5 text-gray-500" aria-hidden="true"></ChevronUpIcon>
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          :class="[
            'absolute z-10 mt-1 w-full overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            optionsHeight ? optionsHeight : 'max-h-[60vh]'
          ]"
        >
          <div v-if="showSearch" class="flex w-full flex-col">
            <input
              v-model="searchQuery"
              class="h-11 w-full border-none bg-gray-100 font-inter text-base font-normal text-gray-900"
              type="search"
              placeholder="Search"
              @click.stop
              @dblclick.stop
              @keydown.space.stop
            />

            <!-- No search results found -->
            <div
              v-if="!filteredMenuList?.length"
              class="flex flex-col items-center justify-center px-3 py-10 text-center"
            >
              <div class="mb-3 self-center rounded bg-gray-50 p-3">
                <SearchIcon class="text-gray-500" />
              </div>
              <p class="mb-1">No result to show</p>
              <p class="text-sm text-gray-400">Please check spelling or try different keywords</p>
            </div>
          </div>

          <ListboxOption
            v-for="menu in filteredMenuList"
            :key="menu.id"
            v-slot="{ active, selected: isSelected }"
            as="template"
            :value="menu"
            :disabled="menu.disabled"
          >
            <li
              :class="[
                active && 'bg-gray-200',
                menu?.disabled ? 'cursor-no-drop text-gray-400' : 'text-gray-900',
                'relative flex cursor-default select-none items-center px-3 py-2',
                isSelected && 'bg-gray-50 text-bluedark-700'
              ]"
            >
              <input
                v-if="showCheckbox"
                type="checkbox"
                :checked="isSelected"
                class="mr-3 h-4 w-4 rounded transition-colors"
              />
              <span v-html="menu.nameHighlighted"></span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref, watch } from 'vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/solid'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'
import EyeIcon from '@/assets/SvgIcons/EyeIcon.svg'
import SunIcon from '@/assets/SvgIcons/SunIcon.svg'
import TinyCircleIcon from '@/assets/SvgIcons/TinyCircleIcon.svg'
import UsersIcon from '@/assets/SvgIcons/UsersIcon.svg'
import TargetCirclesIcon from '@/assets/SvgIcons/TargetCirclesIcon.svg'
import LocationMarkerIcon from '@/assets/SvgIcons/LocationMarkerIcon.svg'
import ExclamationIcon from '@/assets/SvgIcons/ExclamationIcon.svg'
import SearchIcon from '@/assets/SvgIcons/SearchIcon.svg'
import { highlightText } from '@/services/text.service'

export default {
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    EyeIcon,
    SunIcon,
    TinyCircleIcon,
    UsersIcon,
    TargetCirclesIcon,
    LocationMarkerIcon,
    ExclamationIcon,
    SearchIcon
  },
  props: {
    list: {
      type: Array,
      default: () => [],
      required: true
    },
    sortListAlphabetically: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    optionsHeight: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    allowMultipleSelection: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: [String, Array],
      required: false,
      default: (props) => (props.allowMultipleSelection ? [] : '')
    },
    compareBy: {
      type: String,
      required: false,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false,
      required: false
    },
    showCheckbox: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['update'],
  setup(props, context) {
    const selected = ref(props.value)
    const searchQuery = ref('')

    watch(
      () => props.value,
      (newValue) => {
        selected.value = props.allowMultipleSelection ? newValue : { name: newValue ?? '' }
      },
      { immediate: true }
    )

    watch(
      selected,
      (newValue) => {
        context.emit('update', props.allowMultipleSelection ? [...newValue] : { ...newValue })
      },
      { immediate: true }
    )

    return {
      selected,
      searchQuery
    }
  },
  computed: {
    filteredMenuList(props) {
      let filteredList = props.list
      if (typeof this.searchQuery === 'string' && this.searchQuery.length) {
        filteredList = filteredList
          .filter(({ name }) => name.toLowerCase().includes(this.searchQuery.toLowerCase()))
          .map((item) => ({
            ...item,
            nameHighlighted: this.highlightText(item.name, this.searchQuery)
          }))
      } else {
        filteredList = filteredList.map((item) => ({
          ...item,
          nameHighlighted: item.name
        }))
      }
      if (props.sortListAlphabetically === true) {
        filteredList = filteredList.sort((a, b) => a.name.localeCompare(b.name))
      }
      return filteredList
    }
  },
  methods: {
    highlightText,
    clearSearchQuery() {
      this.searchQuery = ''
    }
  }
}
</script>
