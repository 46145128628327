import * as Sentry from '@sentry/browser'
import {
  cloneDeep,
  compact,
  countBy,
  each,
  find,
  flatten,
  flattenDeep,
  keyBy,
  map,
  remove,
  sortBy,
  uniq,
  uniqBy,
  xor
} from 'lodash-es'
import { DateTime } from 'luxon'
import moment from 'moment'
import PersonPlanStates from './PersonPlanStates'
import ProfileChanges from './ProfileChanges.js'

export const sanityCheckPeopleData = (peopleData) => {
  const peopleDict = {}
  let allSubordinates = []
  const filteredPeople = []

  for (let p = 0; p < peopleData.length; p++) {
    let person = peopleData[p]
    peopleDict[person.personId] = person
    if (!person.removed) {
      filteredPeople.push(person)
    }

    if (person?.subordinates) {
      let i = 0
      while (i < person.subordinates.length) {
        if (person.subordinates[i] === person.personId || person.subordinates[i] === '') {
          // Remove invalid subordinate
          person.subordinates.splice(i, 1)
        } else {
          allSubordinates.push(person.subordinates[i])
          i++
        }
      }
    }

    if (person?.managers) {
      let i = 0
      while (i < person.managers.length) {
        if (person.managers[i] === person.personId) {
          // Remove invalid manager
          person.managers.splice(i, 1)
        } else {
          i++
        }
      }
    }
  }

  // Only one manager is allowed. Check for duplicates in the subordinates list
  const counts = countBy(allSubordinates)

  // List of the subordinates that need to be fixed
  let toBeFixed = map(counts, (v, k) => {
    if (v > 1) {
      return k
    }
    return null
  })
  toBeFixed = compact(toBeFixed)

  if (toBeFixed.length > 0) {
    peopleData = peopleData.map((person) => {
      if (xor([toBeFixed, person?.subordinates]).length !== 0) {
        // remove the person from the subordinates list and the toBeFixed list
        remove(person?.subordinates || [], person.personId)
        remove(toBeFixed, person.personId)
      }
      return person
    })
  }

  allSubordinates = map(filteredPeople, 'subordinates')
  allSubordinates = flattenDeep(allSubordinates)

  const unique = allSubordinates.reduce((result, b) => {
    result[b.personId] = result[b.personId] || 0
    result[b.personId] += b.count
    return result
  }, {})

  const hasTwoManagersPersonIdsArray = Object.keys(unique).filter((a) => unique[a] > 1)

  for (let i = 0; i < hasTwoManagersPersonIdsArray.length; i++) {
    let subordinatePersonId = hasTwoManagersPersonIdsArray[i]
    let managerObjIndex = peopleData.findIndex((person) =>
      person?.subordinates?.includes(subordinatePersonId)
    )

    if (managerObjIndex === -1) continue

    let managerObj = peopleData[managerObjIndex]
    let subordinates = managerObj.subordinates

    if (!subordinates || !Array.isArray(subordinates)) {
      subordinates = []
    }

    let index = subordinates.indexOf(subordinatePersonId)
    if (index !== -1) {
      subordinates.splice(index, 1)
    }
  }

  for (let i = 0; i < peopleData.length; i++) {
    let person = peopleData[i]
    if (!person) continue

    let subordinates = person.subordinates || []
    let newSubordinateList = []
    for (let j = 0; j < subordinates.length; j++) {
      let _id = subordinates[j]
      if (peopleDict[_id]) {
        newSubordinateList.push(_id)
        peopleDict[_id].managers = [person.personId]
      }
    }
    person.subordinates = newSubordinateList

    let managers = person.managers
    if (!managers) person.managers = []
    // We cannot have more one manager for now.
    if (managers && managers.length > 1) {
      person.managers = [managers[0]]
    }
  }

  // Remove from the peopleData of `Removed` users
  let removedPersonIds = []
  for (let i = 0; i < peopleData.length; i++) {
    let person = peopleData[i]
    if (person.employeeStatus === 'Removed') {
      removedPersonIds.push(person.personId)
    }
  }

  for (let i = 0; i < peopleData.length; i++) {
    const person = peopleData[i]

    // Skip removed persons
    if (person.employeeStatus === 'Removed') {
      continue
    }

    // Ensure subordinates array exists
    if (!Array.isArray(person.subordinates)) {
      person.subordinates = []
    }

    // Filter out removed subordinates
    let j = 0
    while (j < person.subordinates.length) {
      if (removedPersonIds.includes(person.subordinates[j])) {
        person.subordinates.splice(j, 1)
      } else {
        j++
      }
    }
  }

  /**
   * Some times, the manager field and the subordinate lists are misaligned.
   * This method fixes the misalignment, taking the subordinates list as the ground truth.
   */
  for (let i = 0; i < peopleData.length; i++) {
    const person = peopleData[i] || {}
    const subordinates = person.subordinates || []
    const uniqueSubordinates = [...new Set(subordinates)]
    person.subordinates = uniqueSubordinates

    for (let j = 0; j < uniqueSubordinates.length; j++) {
      const subordinatePersonId = uniqueSubordinates[j]
      const managers = peopleDict[subordinatePersonId]?.managers
      if (!managers || managers.length === 0 || managers[0] !== person.personId) {
        peopleDict[subordinatePersonId].managers = [person.personId]
      }
    }
  }

  return peopleData
}

/**
 * Find the nodes without a manager.
 * Move them to a special node for those without a manager
 * TODO: cover with basic unit tests so we can characterize the behavior and control the changes later
 */
export const fixDiscontinuity = (orginalData, peopleIncludingRemoved, inplace = true) => {
  const peopleData = inplace ? orginalData : cloneDeep(orginalData)

  const filteredPeople = peopleData.filter((person) => {
    return !isRemoved(person)
  })

  const peopleDict = keyBy(filteredPeople, 'personId')

  let subordinateList = map(filteredPeople, (person) => {
    return person?.subordinates || []
  })
  subordinateList = flatten(subordinateList)
  subordinateList = uniq(subordinateList)

  const personIdList = map(filteredPeople, 'personId')
  let noManagerList = xor(subordinateList, personIdList)

  let ceoId = noManagerList.find((pID) => isCEO(peopleDict[pID]))

  if (!ceoId) {
    ceoId = noManagerList.find((pID) => hasCEOAttributes(peopleDict[pID]))
  }

  if (!ceoId) {
    return peopleData
  }

  remove(noManagerList, (pID) => pID === ceoId)
  remove(noManagerList, (pID) => peopleDict[pID]?.isRoot)
  remove(noManagerList, (pID) => !peopleDict[pID])

  // Add the removed person into no manager node
  const removedPeopleIds = peopleIncludingRemoved
    .filter((person) => person?.scenarioMetaData?.state?.includes(PersonPlanStates.Removed))
    .map((person) => person.personId)

  if (noManagerList.length === 0 && removedPeopleIds.length === 0) return peopleData

  // Find the special NO MANAGER node.
  // If it does not exist, create a new one
  let noManagerNode = find(peopleData, (person) => {
    return person.isNoManagerNode
  })

  const boardId = peopleData[0].boardId

  if (noManagerNode) {
    if (!noManagerNode?.subordinates) {
      noManagerNode.subordinates = []
    }

    noManagerNode.subordinates.push(...noManagerList)

    peopleData.push(noManagerNode)
  } else {
    // Create a new no manager node
    noManagerNode = {
      boardId,
      personId: 'p_nomanagernode',
      avatarImg: '',
      subordinates: [...noManagerList],
      managers: [ceoId], // XXX TODO this is part of the hack below for a customer - Change to empty array later
      isNoManagerNode: true,
      name: '',
      role: '',
      department: '',
      isRoot: null
    }

    // XXX TODO remove this later
    // XXX This is the plan "North America Current" of CSI
    // XXX For now we want only this plan to have the no manager node as a root until more thorough testing is done
    if (
      [
        'bd_Xw9S5i0XViMbS5u5gqDKX3',
        'bd_2a2ZyjsmoZsnz6xG40ID8',
        'bd_f0ar9hHUoINYtSNDxvbn2'
      ].includes(boardId)
    ) {
      noManagerNode.managers = []
      noManagerNode.isRoot = true
    }

    if (noManagerNode?.subordinates?.length > 0) {
      noManagerNode?.subordinates.forEach((personId) => {
        try {
          const person = peopleDict[personId]
          if (!person) return
          person.managers = person.managers ?? []
          person.managers.splice(0, 0, noManagerNode.personId)
        } catch (e) {
          console.log(e)
        }
      })

      peopleData.push(noManagerNode)
    }
  }

  return peopleData
}

/**
 * Get new person object with default fields
 */
export const getNewPerson = ({
  boardId,
  newPersonId,
  name,
  role,
  managerObj,
  status,
  officeLocation,
  subordinates,
  department = '',
  scenarioMetaData = {},
  type = null,
  sourceEmployeeId = null,
  startDate = null,
  terminationDate = null,
  email = null,
  isRoot = false
}) => ({
  boardId,
  personId: newPersonId,
  name,
  role: role || '',
  email: email || '',
  avatarImg:
    'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5',
  officeLocation: officeLocation || '',
  subordinates: subordinates || [],
  managers: managerObj?.personId ? [managerObj.personId] : [],
  employeeStatus: status || 'TBH',
  department: department || managerObj?.department || '',
  startDate: startDate || null,
  terminationDate: terminationDate || null,
  scenarioMetaData: {
    ...scenarioMetaData,
    state: [
      ...(scenarioMetaData.state ? [...scenarioMetaData.state] : []),
      PersonPlanStates.NewlyAdded
    ]
  },
  type,
  sourceEmployeeId,
  isRoot
})

/**
 * Get new compensation object with default fields
 */
export const getNewCompensation = ({ personId, boardId, defaultCurrency }) => ({
  personId,
  boardId,
  payPer: '',
  payRate: 0,
  payRateEffectiveDate: null,
  payType: '',
  currency: defaultCurrency
})

export const updateCompObject = ({ personId, boardId, defaultCurrency, comp, patch }) => {
  let result = {}
  if (!comp) {
    result = getNewCompensation({ personId, boardId, defaultCurrency })
  } else {
    result = {
      ...comp
    }
  }

  const {
    payPer = null,
    payRate = null,
    payRateEffectiveDate = null,
    payType = null,
    currency = null
  } = patch

  if (payPer) result.payPer = payPer
  if (payRate) result.payRate = payRate
  if (payRateEffectiveDate) result.payRateEffectiveDate = payRateEffectiveDate
  if (payType) result.payType = payType
  if (currency) result.currency = currency
  result.previousRates = [
    ...(comp?.previousRates || []),
    {
      payRate: comp?.payRate || null,
      payType: comp?.payType || null,
      currency: comp?.currency || null
    }
  ]

  return result
}

/**
 * Duplicates given compensation object and swaps the personId
 */
export const duplicateCompensation = ({ personId, compensationToDuplicate, defaultCurrency }) => ({
  personId,
  boardId: compensationToDuplicate.boardId,
  currency: compensationToDuplicate.currency || defaultCurrency,
  payPer: compensationToDuplicate.payPer,
  payRate: compensationToDuplicate.payRate,
  payRateEffectiveDate: compensationToDuplicate.payRateEffectiveDate,
  payType: compensationToDuplicate.payType
})

/**
 * Make sure that subordinates list exists on person object
 */
const ensureSubordinatesList = (personObj) => {
  if (!personObj.subordinates || !Array.isArray(personObj.subordinates)) {
    personObj.subordinates = []
  }
}

/**
 * Adds suboridinate to manager object
 */
export const addSubordinate = ({ managerObj, subordinateId }) => {
  const result = { ...managerObj }

  if (result && !result.isNoManagerNode) {
    ensureSubordinatesList(result)
    const newSubordinates = uniq([...result.subordinates, subordinateId])
    result.subordinates = newSubordinates
  }

  return result
}

/**
 * Get new role object
 */
export const getNewRole = ({ newPersonId, managerObj, boardId }) => {
  return {
    newPersonId,
    managerObj,
    boardId,
    officeLocation: managerObj?.officeLocation || null,
    status: 'TBH',
    name: 'TBH',
    subordinates: [],
    role: '',
    scenarioMetaData: {},
    department: '',
    email: ''
  }
}

/**
 * Check if person has status 'Moved' in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isMoved = (person) =>
  person.scenarioMetaData?.state?.includes(PersonPlanStates.Moved) || false

export const isRemovedManager = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.RemovedManager) || false

export const isRif = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.RIF) || false

export const isBenched = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.Benched) || false

export const isExited = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.Exited) || false

export const isNewlyAdded = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.NewlyAdded) || false

export const isRemoved = (person) =>
  person?.removed === true ||
  person?.employeeStatus === 'Removed' ||
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.Removed) ||
  false

/**
 * Check if person has status 'Edited' in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isEdited = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.Edited)

export const setEdited = (person) => {
  const result = ensureMetaProperties(person)

  if (!result?.scenarioMetaData?.state?.includes(PersonPlanStates.Edited)) {
    result?.scenarioMetaData?.state?.push(PersonPlanStates.Edited)
  }

  return result
}

export const setScenarioStatus = ({ person, status }) => {
  const result = ensureMetaProperties(person)

  if (!result?.scenarioMetaData?.state?.includes(status)) {
    result?.scenarioMetaData?.state?.push(status)
  }

  return result
}

export const removeScenarioStatus = ({ person, status }) => {
  const result = ensureMetaProperties(person)

  if (result?.scenarioMetaData?.state?.includes(status)) {
    result.scenarioMetaData.state = result?.scenarioMetaData?.state.filter((s) => s !== status)
  }

  return result
}

export const setNewReport = (person) => {
  const result = ensureMetaProperties(person)

  if (!result?.scenarioMetaData?.state?.includes(PersonPlanStates.NewReport)) {
    result?.scenarioMetaData?.state?.push(PersonPlanStates.NewReport)
  }

  return result
}

/**
 * Check if person is on notice period / leaver state in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isLeaving = (person) => {
  return (
    (person?.terminationDate &&
      DateTime.fromFormat(person?.terminationDate, 'yyyy-MM-dd').diffNow('days').days > 0) ||
    false
  )
}

/**
 * Checks if person is terminated
 * @param person
 * @returns {Boolean}
 */
export const isTerminated = (person) => {
  return (
    (person?.terminationDate &&
      DateTime.fromFormat(person?.terminationDate, 'yyyy-MM-dd').diffNow('days').days <= 0) ||
    false
  )
}

export const isBackfill = (person) => {
  return person?.scenarioMetaData?.state?.includes(PersonPlanStates.Backfill)
}

export const isBackfilled = (person) => {
  return person?.scenarioMetaData?.state?.includes(PersonPlanStates.Backfilled)
}

/**
 * Get record of last role change
 * @param person
 * @returns {*}
 */
export const getRoleChangedRecord = (person) =>
  person?.scenarioMetaData?.changes?.findLast(
    (change) => change.action === ProfileChanges.RoleChange
  )

/**
 * Check if person has role changed in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isRoleChanged = (person) => getRoleChangedRecord(person) != null

/**
 * Get empty object describing role change
 * @param fromRole
 * @param toRole
 * @returns {{action: string, from, to}}
 */
export const newRoleChangeRecord = (fromRole, toRole) => ({
  action: ProfileChanges.RoleChange,
  from: fromRole,
  to: toRole
})

/**
 * Returns person object with "Moved" state and a new manager
 */
export const applyPersonMoved = ({ person, newManagerId }) => {
  const newPerson = ensureMetaProperties(person)

  if (newManagerId) {
    newPerson.managers = [newManagerId]
  }

  if (!isNewlyAdded(newPerson) && !isMoved(newPerson)) {
    newPerson.scenarioMetaData?.state.push(PersonPlanStates.Moved)
  }

  return newPerson
}

export const markAsRemoved = (person) => {
  const removedPerson = { ...person }

  removedPerson.removed = true
  removedPerson.employeeStatus = 'Removed'

  // Set the status value to display the avatar change status in the scenario editor view
  if (!removedPerson?.scenarioMetaData) {
    removedPerson.scenarioMetaData = {}
  }
  if (!removedPerson?.scenarioMetaData?.state) {
    removedPerson.scenarioMetaData.state = []
  }

  removedPerson?.scenarioMetaData?.state?.push(PersonPlanStates.Removed)
  removedPerson.terminationDate = moment().format('YYYY-MM-DD')

  return removedPerson
}

export const markAsRif = (person) => {
  const newDoc = { ...ensureMetaProperties(person) }

  if (!isRif(newDoc)) {
    newDoc.scenarioMetaData.state.push(PersonPlanStates.RIF)
  }

  return newDoc
}

export const isCEO = (person) => {
  return (
    person?.isRoot ||
    person?.role?.toLowerCase() === 'ceo' ||
    person?.role?.toLowerCase().includes('chief executive officer')
  )
}

export const hasCEOAttributes = (person) => {
  return person?.managers?.length === 0 && person?.subordinates?.length > 0
}

// apply just the edited state to person object
export const applyEditedState = ({ oldPersonObject }) => {
  const personObj = cloneDeep(oldPersonObject)

  if (!personObj.scenarioMetaData) {
    personObj.scenarioMetaData = { state: [] }
  } else if (!Array.isArray(personObj.scenarioMetaData.state)) {
    personObj.scenarioMetaData.state = []
  }

  if (!personObj.scenarioMetaData.state.includes(PersonPlanStates.Edited))
    personObj.scenarioMetaData.state.push(PersonPlanStates.Edited)

  return personObj
}

export const ensureMetaProperties = (personObject) => {
  if (!personObject) return null
  if (personObject.scenarioMetaData?.state && personObject.scenarioMetaData?.changes)
    return personObject

  const person = { ...personObject }

  if (!person?.scenarioMetaData) {
    person.scenarioMetaData = {}
  }
  if (!person?.scenarioMetaData?.state) {
    person.scenarioMetaData.state = []
  }
  if (!person?.scenarioMetaData?.changes) {
    person.scenarioMetaData.changes = []
  }

  return person
}

export const fixedPeopleDataHelper = ({ peopleData }) => {
  try {
    const managerUpdatedPeopleData = sanityCheckPeopleData(peopleData)

    let fixedPeopleData = fixDiscontinuity(managerUpdatedPeopleData, peopleData)

    for (let i = 0; i < fixedPeopleData.length; i++) {
      let person = fixedPeopleData[i]
      let subordinates = person.subordinates || []

      person.subordinates = [...new Set(subordinates)]
    }

    fixedPeopleData = uniqBy(fixedPeopleData, (e) => {
      return e.personId
    })

    fixedPeopleData = sortBy(fixedPeopleData, 'name')

    //move nomanagernode to the last position
    const noManagerNodeIndex = fixedPeopleData.findIndex((person) => person.isNoManagerNode)

    if (noManagerNodeIndex !== -1) {
      const noManagerNode = fixedPeopleData[noManagerNodeIndex]
      fixedPeopleData.splice(noManagerNodeIndex, 1)
      fixedPeopleData.push(noManagerNode)
    }

    return fixedPeopleData
  } catch (err) {
    console.error(err)
    Sentry?.captureException(err)
    return []
  }
}

export const peopleForSharedPlan = ({ peopleData }) => {
  try {
    const managerUpdatedPeopleData = sanityCheckPeopleData(peopleData)

    let fixedPeopleData = fixDiscontinuity(managerUpdatedPeopleData, peopleData)

    each(fixedPeopleData, (person = {}) => {
      person.subordinates = uniq(person?.subordinates || [])
    })

    // Ensure there are no duplicates in the subordinates list
    fixedPeopleData = uniqBy(fixedPeopleData, (e) => {
      return e.personId
    })

    fixedPeopleData = sortBy(fixedPeopleData, 'name')

    return fixedPeopleData
  } catch (err) {
    console.error(err)
    Sentry?.captureException(err)
    return []
  }
}

/**
 * Validate data before updating people data to Vuex.
 * If there is any weird data, it warns through console.warn and changes the data to avoid the console error in the utils.
 *
 * @param {*} peopleData
 * @returns peopleData
 */
export const validatePeopleData = (peopleData) => {
  const personIdDict = keyBy(peopleData, 'personId')

  return peopleData.map((person) => {
    // Check valid subordinates data
    if (person?.subordinates?.length && !person.isNoManagerNode) {
      let i = 0
      while (i < person.subordinates.length) {
        if (!personIdDict[person.subordinates[i]]) {
          // Remove invalid subordinate
          person.subordinates.splice(i, 1)
        } else {
          i++
        }
      }
    }

    /* Add the another condition here */

    return person
  })
}

export const isNoManagerNode = (person) => {
  return person?.isNoManagerNode || person.personId === 'p_nomanagernode'
}
