const ACCESS_LEVELS = [
  'no_access',
  'admin',
  'executive',
  'manager',
  'individual_contributor',
  'hr',
  'finance'
]

export const ACCESS_LEVELS_DICT = {
  NO_ACCESS: 'no_access',
  ADMIN: 'admin',
  EXECUTIVE: 'executive',
  MANAGER: 'manager',
  INDIVIDUAL_CONTRIBUTOR: 'individual_contributor',
  HR: 'hr',
  FINANCE: 'finance',
  REVIEWER: 'reviewer'
}

export const ACCESS_LEVELS_GROUPS = {
  ALL: [
    ACCESS_LEVELS_DICT.INDIVIDUAL_CONTRIBUTOR,
    ACCESS_LEVELS_DICT.MANAGER,
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.HR,
    ACCESS_LEVELS_DICT.FINANCE
  ],
  ABOVE_IC: [
    ACCESS_LEVELS_DICT.MANAGER,
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.HR,
    ACCESS_LEVELS_DICT.FINANCE
  ],
  TOP_LEVEL_FINANCE: [
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.FINANCE
  ],
  TOP_LEVEL_FINANCE_HR: [
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.FINANCE,
    ACCESS_LEVELS_DICT.HR
  ],
  TOP_LEVEL: [ACCESS_LEVELS_DICT.ADMIN, ACCESS_LEVELS_DICT.EXECUTIVE]
}

const FORMATTED_ACCESS_LEVEL_DICT = {
  [ACCESS_LEVELS_DICT.NO_ACCESS]: 'No Access',
  [ACCESS_LEVELS_DICT.ADMIN]: 'Admin',
  [ACCESS_LEVELS_DICT.EXECUTIVE]: 'Executive',
  [ACCESS_LEVELS_DICT.MANAGER]: 'Manager',
  [ACCESS_LEVELS_DICT.INDIVIDUAL_CONTRIBUTOR]: 'Individual Contributor',
  [ACCESS_LEVELS_DICT.HR]: 'HR',
  [ACCESS_LEVELS_DICT.FINANCE]: 'Finance'
}

const userHasRouteAccess = ({ role, permittedRoles, blockedRoles }) => {
  if (!permittedRoles && !blockedRoles) return true

  if (blockedRoles && blockedRoles.includes(role)) {
    console.log("user's role is blocked from this page")
    return false
  }
  if (permittedRoles && !permittedRoles.includes(role)) {
    console.log("user's role does not have access to this page")
    return false
  }

  return true
}

export { ACCESS_LEVELS, FORMATTED_ACCESS_LEVEL_DICT, userHasRouteAccess }
