import { getHierarchy, getHierarchyWithFilterDebounced } from '@/services/people-api.service'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref, watch } from 'vue'
import { stratify } from 'd3-hierarchy'
import { isDefault } from '@/hooks/use-filters'
import { useHierarchyOptions, useHierarchyState } from './use-hierarchy'
import { isEmpty } from 'lodash-es'
import { ascending } from 'd3-array'
import { useStore } from 'vuex'

export function useHierarchyFetch(boardId, view = null) {
  if (!unref(boardId)) return {}
  const { getters } = useStore()

  const uid = computed(() => getters.uid)

  const viewKey = computed(() => {
    if (!unref(view)) {
      return unref(boardId)
    }

    return `${unref(boardId)}-${unref(view)}`
  })

  const { options } = useHierarchyOptions(boardId, view)
  const { setHierarchy, setHierarchyData, setIsLoading, setIsRefreshing } = useHierarchyState(
    boardId,
    view
  )

  const optionsForView = computed(() => options.value[viewKey.value] || {})

  const queryKey = computed(() => {
    const queryOptions = {
      uid: unref(uid),
      boardId: unref(boardId),
      view: unref(view),
      filters: optionsForView.value?.filters || null,
      includeApprovedRoles: optionsForView.value?.includeApprovedRoles || false,
      collapseOn: isEmpty(optionsForView.value?.collapseOn)
        ? null
        : optionsForView.value?.collapseOn
    }

    if (queryOptions.filters === null || isDefault(queryOptions.filters)) {
      delete queryOptions.filters
    }

    return ['hierarchy', { ...queryOptions }]
  })

  /**
   * Fetches the hierarchy data from the API. Simple array of { id, parentId }
   * INFO:
   * Frontend cache for hierarchy is now disabled by setting cacheTime to 0.
   * This means the data will not be stored in the cache and a new request will be made every time.
   *
   * Further reading: https://tanstack.com/query/latest/docs/framework/vue/guides/caching
   */
  const { isPending, isRefetching } = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      if (!optionsForView.value?.filters || isDefault(optionsForView.value?.filters)) {
        return getHierarchy({
          boardId: unref(boardId),
          includeApprovedRoles: optionsForView.value?.includeApprovedRoles,
          collapseOn: isEmpty(optionsForView.value?.collapseOn)
            ? null
            : optionsForView.value?.collapseOn
        })
      } else {
        return getHierarchyWithFilterDebounced({
          boardId: unref(boardId),
          filters: optionsForView.value?.filters,
          includeApprovedRoles: optionsForView.value?.includeApprovedRoles,
          collapseOn: isEmpty(optionsForView.value?.collapseOn)
            ? null
            : optionsForView.value?.collapseOn,
          signal
        })
      }
    },
    select: (data) => {
      setHierarchy(buildHierarchy(data))
      setHierarchyData(data)

      return data
    },
    enabled: () => !isEmpty(unref(boardId)),
    structuralSharing: (oldData, newData) => {
      if (oldData && newData && oldData.length === newData.length) {
        for (let i = 0; i < oldData.length; i++) {
          if (dataChanged(oldData[i], newData[i])) {
            return newData
          }
        }
        return oldData
      }
      return newData
    },
    cacheTime: 0
    // staleTime: 5 * 1000 * 60
  })

  function dataChanged(oldNode, newNode) {
    if (
      oldNode.id !== newNode.id ||
      oldNode.parentId !== newNode.parentId ||
      oldNode.data?.skip !== newNode.data?.skip ||
      oldNode.data?.collapsed !== newNode.data?.collapsed
    ) {
      return newNode
    }
    return oldNode
  }

  watch(
    isPending,
    () => {
      setIsLoading(isPending.value)
    },
    { immediate: true }
  )

  watch(
    isRefetching,
    () => {
      setIsRefreshing(isRefetching.value)
    },
    { immediate: true }
  )

  function buildHierarchy(data) {
    //ignore all employee-data nodes in the hierarchy
    data = data.filter(
      (node) => node.id !== 'p_employee_data' && node.parentId !== 'p_employee_data'
    )

    const result = stratify()
      .id((node) => node.id)
      .parentId((node) => node.parentId)(data)

    result.sort((a, b) => {
      return a.data.order - b.data.order || ascending(a.id, b.id)
    })

    result.eachAfter((node) => {
      let directSubordinates = node.children?.filter((child) => !child.data?.skip).length || 0
      let totalSubordinates = directSubordinates

      if (node.children) {
        const len = node.children.length
        for (let i = 0; i < len; i++) {
          const child = node.children[i]
          if (child.data && child.data.collapsed && !child.data.skip) {
            directSubordinates += child.children ? child.children.length : 0
          }

          totalSubordinates += child.data.totalSubordinates || 0
        }
      }

      node.data.directSubordinates = directSubordinates
      node.data.totalSubordinates = totalSubordinates
    })

    return result
  }
}

export function useHierarchyHelper(boardId) {
  const queryClient = useQueryClient()

  async function invalidate() {
    await queryClient.invalidateQueries({ queryKey: ['hierarchy', { boardId: unref(boardId) }] })
  }

  return {
    invalidate
  }
}
