import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFilterStore = defineStore('filter', () => {
  const filterHistory = ref({})

  const updateFilterHistory = (filterKey, managerId, filters) => {
    filterHistory.value[filterKey] = {
      [managerId]: filters
    }
  }

  return { filterHistory, updateFilterHistory }
})

export const useFilterValuesStore = defineStore('filterValues', () => {
  const values = ref({})
  const categories = ref({})
  const unfilteredCategories = ref({})

  // Track pending updates to batch them together
  const pendingUpdates = ref({})
  const updateTimeouts = {}

  /**
   * Schedules a batched update for a specific key
   * This ensures we only apply updates once per animation frame
   */
  const scheduleUpdate = (key) => {
    // Clear existing timeout for this key if any
    if (updateTimeouts[key]) {
      window.clearTimeout(updateTimeouts[key])
    }

    // Schedule a new update using setTimeout with 0ms to batch in the next event loop
    updateTimeouts[key] = window.setTimeout(() => {
      // Use requestAnimationFrame to align with browser rendering for optimal performance
      window.requestAnimationFrame(() => {
        // Apply all pending updates for this key at once
        const updates = pendingUpdates.value[key]
        if (updates) {
          if (updates.values !== undefined) {
            values.value[key] = updates.values
          }
          if (updates.categories !== undefined) {
            categories.value[key] = updates.categories
          }
          if (updates.unfilteredCategories !== undefined) {
            unfilteredCategories.value[key] = updates.unfilteredCategories
          }

          // Clear the pending updates for this key
          delete pendingUpdates.value[key]
          delete updateTimeouts[key]
        }
      })
    }, 1000)
  }

  /**
   * Batch updates all filter data at once for a specific key
   * This is more efficient than calling individual setters
   */
  const batchUpdate = (key, newValues, newCategories, newUnfilteredCategories) => {
    if (!pendingUpdates.value[key]) {
      pendingUpdates.value[key] = {}
    }

    if (newValues !== undefined) {
      pendingUpdates.value[key].values = newValues
    }
    if (newCategories !== undefined) {
      pendingUpdates.value[key].categories = newCategories
    }
    if (newUnfilteredCategories !== undefined) {
      pendingUpdates.value[key].unfilteredCategories = newUnfilteredCategories
    }

    scheduleUpdate(key)
  }

  return {
    categories,
    values,
    unfilteredCategories,
    batchUpdate
  }
})
