import { useStorage } from '@vueuse/core'
import { readonly } from 'vue'

const highlightMode = useStorage('highlight-mode', null)
const highlightModeSecondary = useStorage('highlight-mode-secondary', null)
const outlineHighlightMode = useStorage('outline-highlight-mode', null)
const outlineHighlightModeSecondary = useStorage('outline-highlight-mode-secondary', null)

export default function useOrgChartColors() {
  /**
   * update selected highlight mode
   * @param {Object} options - Highlight options
   * @param {string} options.mode - Fill highlight mode
   * @param {string} options.secondary - Fill highlight secondary mode
   * @param {string} options.outlineMode - Outline highlight mode
   * @param {string} options.outlineSecondary - Outline highlight secondary mode
   */
  const setNewHighlightMode = ({ mode, secondary = null, outlineMode, outlineSecondary = null }) => {
    if (mode !== undefined) {
      highlightMode.value = mode
      highlightModeSecondary.value = secondary
    }
    
    if (outlineMode !== undefined) {
      outlineHighlightMode.value = outlineMode
      outlineHighlightModeSecondary.value = outlineSecondary
    }
  }

  // eslint-disable-next-line no-unused-vars
  const setPeople = (people) => {
    // activeEmployees.value = people
    // peopleColorMapCache.value = peopleColorMap.value // Refresh the color map cache
  }

  const getContrastRatio = (l1, l2) => {
    // Calculate the contrast ratio between two luminance values.
    const lighter = Math.max(l1, l2)
    const darker = Math.min(l1, l2)
    return (lighter + 0.05) / (darker + 0.05)
  }

  const getLuminance = (r, g, b) => {
    // Calculate relative luminance in sRGB color space
    return (0.2126 * r) / 255 + (0.7152 * g) / 255 + (0.0722 * b) / 255
  }

  /**
   * Adjusts the brightness of a given hex color based on a scale factor.
   * Will make the color darker if the new color's contrast ratio
   * with the original color is less than 4.5.
   *
   * @param {string} hex - The original color in hex format (e.g., '#FF00FF').
   * @param {number} scaleFactor - The factor by which to adjust the brightness.
   *                               Positive values make the color lighter, negative values make it darker.
   *
   * @returns {string} The adjusted color in hex format (e.g., '#FF00FF').
   */
  const adjustColorForContrast = (hex, scaleFactor) => {
    hex = hex.replace('#', '')
    const redComponent = parseInt(hex.substring(0, 2), 16)
    const greenComponent = parseInt(hex.substring(2, 4), 16)
    const blueComponent = parseInt(hex.substring(4, 6), 16)

    const originalLuminance = getLuminance(redComponent, greenComponent, blueComponent)

    let newR = Math.floor((255 - redComponent) * Math.abs(scaleFactor) + redComponent)
    let newG = Math.floor((255 - greenComponent) * Math.abs(scaleFactor) + greenComponent)
    let newB = Math.floor((255 - blueComponent) * Math.abs(scaleFactor) + blueComponent)

    const newLuminance = getLuminance(newR, newG, newB)

    if (getContrastRatio(originalLuminance, newLuminance) < 4.5) {
      if (originalLuminance < 0.5) {
        // Force to go lighter
        newR = Math.floor((255 - redComponent) * Math.abs(scaleFactor) + redComponent)
        newG = Math.floor((255 - greenComponent) * Math.abs(scaleFactor) + greenComponent)
        newB = Math.floor((255 - blueComponent) * Math.abs(scaleFactor) + blueComponent)
      } else {
        // Make it darker
        newR = Math.floor(redComponent * (1 - Math.abs(scaleFactor)))
        newG = Math.floor(greenComponent * (1 - Math.abs(scaleFactor)))
        newB = Math.floor(blueComponent * (1 - Math.abs(scaleFactor)))
      }
    }

    const newHexR = `0${newR.toString(16)}`.slice(-2)
    const newHexG = `0${newG.toString(16)}`.slice(-2)
    const newHexB = `0${newB.toString(16)}`.slice(-2)

    return `#${newHexR}${newHexG}${newHexB}`
  }

  /**
   * Lightens a given hex color by a specified percentage.
   *
   * @param {string} hex - The original color in hex format (e.g., '#FF00FF').
   *                       The hash sign '#' is optional.
   * @param {number} percent - The percentage by which to lighten the color.
   *                           Must be a value between 0 and 100.
   *
   * @returns {string} The lightened color in hex format (e.g., '#FFAAFF').
   */
  const lightenHexColor = (hex, percent) => {
    hex = hex.replace('#', '')

    let redComponent = parseInt(hex.substring(0, 2), 16)
    let greenComponent = parseInt(hex.substring(2, 4), 16)
    let blueComponent = parseInt(hex.substring(4, 6), 16)

    redComponent = Math.min(
      255,
      Math.max(0, Math.round(redComponent + (255 - redComponent) * (percent / 100)))
    )
    greenComponent = Math.min(
      255,
      Math.max(0, Math.round(greenComponent + (255 - greenComponent) * (percent / 100)))
    )
    blueComponent = Math.min(
      255,
      Math.max(0, Math.round(blueComponent + (255 - blueComponent) * (percent / 100)))
    )

    redComponent = redComponent.toString(16).padStart(2, '0')
    greenComponent = greenComponent.toString(16).padStart(2, '0')
    blueComponent = blueComponent.toString(16).padStart(2, '0')

    return `#${redComponent}${greenComponent}${blueComponent}`
  }

  return {
    setPeople,
    setNewHighlightMode,
    highlightMode: readonly(highlightMode),
    highlightModeSecondary: readonly(highlightModeSecondary),
    outlineHighlightMode: readonly(outlineHighlightMode),
    outlineHighlightModeSecondary: readonly(outlineHighlightModeSecondary),
    adjustColorForContrast,
    lightenHexColor
  }
}
