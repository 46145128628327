import pako from 'pako'
import JSZip from 'jszip'
import { Buffer } from 'buffer'
import { differenceWith, isEmpty, isEqual, sortBy } from 'lodash-es'
import { strFromU8, gunzipSync, decompressSync } from 'fflate'
import { DateTime } from 'luxon'

/**
 * Compress a file object into a zip file
 * @param {File} file in-memory file object
 * @param {String} name file name to set in the zipped file
 * @param {Number} level number between 1 (best speed) and 9 (best compression)
 */
export async function compressFile(file, name = 'zippedFile.tmp', level = 1) {
  const zip = new JSZip()
  zip.file(name, file)
  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level }
  })
}

/**
 * Compress multiple files into a zip file
 * @param {Array<{name: string, content: File}>} files Array of {name, content} objects to zip
 * @param {Number} level number between 1 (best speed) and 9 (best compression)
 */
export async function compressFiles(files, level = 1) {
  const zip = new JSZip()

  // Add each file to the zip
  files.forEach(({ name, content }) => {
    zip.file(name, content)
  })

  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level }
  })
}

export function formatFileSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Bytes'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i]
}

/**
 * Compress data of any non-null type.
 * Use compression level 1 which prefers speed over compression size
 * Note: Code tested with object and array of objects but should also work with other types
 *
 * @param {*} data - non null object of any type
 * @returns compressed base64 encoded string
 */
export function compress(data, level = 1) {
  if (!data) return null
  const deflated = pako.deflate(JSON.stringify(data), { level })
  return Buffer.from(deflated).toString('base64')
}

export function compressString(data, level = 1) {
  if (!data) return null
  const deflated = pako.deflate(data, { level })
  return Buffer.from(deflated).toString('base64')
}

/**
 * Decompress a json object or array of objects that was compressed using compress() above
 *
 * @param {*} data - base64 encoded string
 * @returns decompressed json data
 */
export function decompressJson(str, level = 1) {
  if (!str) return null
  const buffer = Buffer.from(str, 'base64')
  const jsonStr = pako.inflate(buffer, { to: 'string', level })
  return JSON.parse(jsonStr)
}

export function decompressPeople(data) {
  const uint8Array = new Uint8Array(data)
  const decompressed = gunzipSync(uint8Array)
  return JSON.parse(strFromU8(decompressed))
}

export function decompressString(str, level = 1) {
  if (!str) return null
  const buffer = Buffer.from(str, 'base64')
  const decompStr = pako.inflate(buffer, { to: 'string', level })
  return decompStr
}

export function decompressBufferFflate(buffer) {
  const uint8Array = new Uint8Array(buffer)
  const decompressed = decompressSync(uint8Array)
  const decompressedStr = strFromU8(decompressed)
  return decompressedStr
}

// Alternative to pako utility functions above which is both faster and compresses more
// Source: https://github.com/101arrowz/fflate
export function decompressBase64ToJsonFflate(base64Str) {
  const uint8Array = Buffer.from(base64Str, 'base64')
  const decompressed = gunzipSync(uint8Array)
  const decompressedStr = strFromU8(decompressed)
  return JSON.parse(decompressedStr)
}

export function decompressBufferToJsonFflate(buffer) {
  const uint8Array = new Uint8Array(buffer)
  const decompressed = gunzipSync(uint8Array)
  const decompressedStr = strFromU8(decompressed)
  return JSON.parse(decompressedStr)
}

export function isGenericArrayEqual(x, y) {
  if (!x && !y) return true
  if (!x && y) return false
  if (x && !y) return false
  if (x.length !== y.length) return false

  const difference = differenceWith(x, y, (obj1, obj2) => {
    return isEqual(obj1, obj2)
  })

  return isEmpty(difference)
}

export function isArrayEqual(x, y) {
  const difference = differenceWith(x, y, (obj1, obj2) => {
    return obj1.id === obj2.id && obj1.parentId === obj2.parentId
  })

  return isEmpty(difference)
}

export function splitStringBySecondSpace(str) {
  const words = str.split(' ')
  const result = []
  let currentString = ''

  for (let i = 0; i < words.length; i++) {
    if ((i + 1) % 3 === 0) {
      // Split on every second space (every 3rd word)
      result.push(currentString.trim())
      currentString = ''
    }
    currentString += words[i] + ' '
  }

  // Add the remaining part of the string
  if (currentString !== '') {
    result.push(currentString.trim())
  }

  return result
}

export function areArraysOfStringsEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }

  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

export async function sleep(durationMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, durationMs)
  }).then((response) => {
    return response
  })
}

/**
 * Allows only numeric input in the event.
 * @param {Event} evt - The input event to handle.
 * @returns {void}
 */
export function isKeyNumeric(evt) {
  const event = evt || window.event
  const key = event.keyCode || event.which

  if (
    (!event.shiftKey &&
      !event.altKey &&
      !event.ctrlKey &&
      // numbers
      key >= 48 &&
      key <= 57) ||
    // Numeric keypad
    (key >= 96 && key <= 105) ||
    // Backspace and Tab and Enter
    key === 8 ||
    key === 9 ||
    key === 13 ||
    // Home and End
    key === 35 ||
    key === 36 ||
    // left and right arrows
    key === 37 ||
    key === 39 ||
    // Del and Ins
    key === 46 ||
    key === 45
  ) {
    // input is VALID
    return true
  } else {
    // input is INVALID
    event.returnValue = false
    if (event.preventDefault) event.preventDefault()
    return false
  }
}
/**
 * Download a file sent from expressJs using res.download(...).
 * Return the file as an ArrayBuffer, which can be downloaded directly to the user using 'downloadjs'.
 */
export async function readFile(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.readAsArrayBuffer(file)
  })
}

export function sortDataByGivenOrder({ data, order, sortByProperty }) {
  // Create a lookup object from the order array for sorting
  const orderLookup = order.reduce((acc, cur) => {
    acc[cur.name] = cur.order
    return acc
  }, {})
  // Sort the data array based on the order defined in the lookup
  const sortedData = sortBy(data, (item) => orderLookup[item[sortByProperty]])
  return sortedData
}

export function isFutureDate(date) {
  if (!date) return false
  const parsedDate = DateTime.fromFormat(date, 'yyyy-MM-dd')
  if (!parsedDate.isValid) return false
  return parsedDate.diffNow('days').days > 0 || false
}

/**
 * Sorts the headcount distribution data based on a given category order
 *
 * @param {Object} result - The original data result from the API
 * @param {String} categoryKey - The category key to sort by
 * @param {Array} newOrder - The desired order for categories (optional)
 * @returns {Object} - The sorted data
 */
export function sortHeadcountDistributionAxis(result, categoryKey, newOrder) {
  // If no new order is provided, use the existing order from the result
  let orderToUse = newOrder || result.categoryOrder

  if (!orderToUse?.length || !categoryKey || !result.data[categoryKey]) {
    return result
  }

  // First, ensure all categories from the result are represented in the order
  const existingOrderedCategories = orderToUse.map((item) => item.name)
  const newCategories = result.data[categoryKey].categories
    .filter((cat) => !existingOrderedCategories.includes(cat))
    .map((cat, idx) => ({
      name: cat,
      order: existingOrderedCategories.length + idx
    }))

  orderToUse = [...orderToUse, ...newCategories]
  orderToUse = orderToUse.filter((item) => result.data[categoryKey].categories.includes(item.name))

  // Create the reordered data structure
  return {
    ...result,
    data: {
      [categoryKey]: {
        categories: orderToUse.map((item) => item.name),
        actual: {
          data: result.data[categoryKey].actual.data.map((series) => ({
            ...series,
            data: orderToUse.map((item) => {
              const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
              return series.data[originalIndex]
            })
          }))
        },
        percent: {
          data: result.data[categoryKey].percent.data.map((series) => ({
            ...series,
            data: orderToUse.map((item) => {
              const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
              return series.data[originalIndex]
            })
          }))
        }
      }
    },
    categoryOrder: orderToUse
  }
}

/**
 * Sorts the headcount cost distribution data based on a given category order
 *
 * @param {Object} result - The original data result from the API
 * @param {String} categoryKey - The category key to sort by
 * @param {Array} newOrder - The desired order for categories (optional)
 * @param {Boolean} isAverages - The isAverages flag for the data
 * @returns {Object} - The sorted data
 */
export function sortHeadcountCostDistributionAxis(result, categoryKey, newOrder, isAverages) {
  // If no new order is provided, use the existing order from the result
  let orderToUse = newOrder || result.categoryOrder

  if (!orderToUse?.length || !categoryKey || !result.data[categoryKey]) {
    return result
  }

  // First, ensure all categories from the result are represented in the order
  const existingOrderedCategories = orderToUse.map((item) => item.name)
  const newCategories = result.data[categoryKey].categories
    .filter((cat) => !existingOrderedCategories.includes(cat))
    .map((cat, idx) => ({
      name: cat,
      order: existingOrderedCategories.length + idx
    }))

  orderToUse = [...orderToUse, ...newCategories]
  orderToUse = orderToUse.filter((item) => result.data[categoryKey].categories.includes(item.name))

  // Create the reordered data structure
  const sortedData = {
    ...result,
    data: {
      [categoryKey]: {
        categories: orderToUse.map((item) => item.name),
        actual: {
          data: result.data[categoryKey].actual.data.map((series) => ({
            ...series,
            data: orderToUse.map((item) => {
              const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
              return series.data[originalIndex]
            })
          }))
        },
        percent: {
          data: result.data[categoryKey].percent.data.map((series) => ({
            ...series,
            data: orderToUse.map((item) => {
              const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
              return series.data[originalIndex]
            })
          }))
        }
      }
    },
    categoryOrder: orderToUse
  }

  // Ensure options includes the correct isAverages flag
  const options = {
    ...(result.options || {}),
    isAverages: isAverages
  }

  return {
    ...sortedData,
    options: options, // Use the updated options
    categoryOrder: orderToUse
  }
}

/**
 * Sorts the organization map data based on a given category order
 *
 * @param {Object} orgData - The original org map data from the API
 * @param {Array} newOrder - The desired order for categories (optional)
 * @returns {Object} - The sorted data
 */
export function sortOrgMapData(orgData, newOrder) {
  const orderToUse = newOrder || orgData.categoryOrder

  if (!orderToUse?.length || !orgData?.dataset) {
    return orgData
  }

  // Get the first dataset key (there should only be one)
  const datasetKey = Object.keys(orgData.dataset)[0]
  const proportionChart = orgData.dataset[datasetKey].proportionChart

  if (!proportionChart) {
    return orgData
  }

  const existingCategories = proportionChart.categories
  const newOrderCategories = orderToUse.map((item) => item.name)
  const unorderedCategories = existingCategories.filter((cat) => !newOrderCategories.includes(cat))

  const completeOrder = [
    ...orderToUse,
    ...unorderedCategories.map((cat, idx) => ({
      name: cat,
      order: orderToUse.length + idx
    }))
  ]

  const finalOrder = completeOrder.filter((item) => existingCategories.includes(item.name))

  const newCategories = finalOrder.map((item) => item.name)

  // Create new reordered data structure
  return {
    ...orgData,
    dataset: {
      [datasetKey]: {
        ...orgData.dataset[datasetKey],
        // Reorder the children array
        children: newCategories.map((categoryName) => {
          return orgData.dataset[datasetKey].children.find((child) => child.name === categoryName)
        }),
        proportionChart: {
          categories: newCategories,
          data: newCategories
            .map((newCategory) => {
              const series = proportionChart.data.find((s) => s.name === newCategory)
              if (!series) return null

              const newData = newCategories.map((category) => {
                const categoryIndex = existingCategories.indexOf(category)
                return series.data[categoryIndex]
              })

              return {
                name: newCategory,
                data: newData
              }
            })
            .filter(Boolean),
          exportableData: proportionChart.exportableData
        }
      }
    },
    categoryOrder: finalOrder
  }
}

/**
 * Sorts the span of control data based on a given category order
 *
 * @param {Object} result - The original data result from the API
 * @param {String} categoryKey - The category key to sort by
 * @param {Array} newOrder - The desired order for categories (optional)
 * @returns {Object} - The sorted data
 */
export function sortSpanOfControlData(result, categoryKey, newOrder) {
  // If no new order is provided, use the existing order from the result
  let orderToUse = newOrder || result.categoryOrder

  if (!orderToUse?.length || !categoryKey || !result.data[categoryKey]) {
    return result
  }

  // First, ensure all categories from the result are represented in the order
  const existingOrderedCategories = orderToUse.map((item) => item.name)
  const newCategories = result.data[categoryKey].categories
    .filter((cat) => !existingOrderedCategories.includes(cat))
    .map((cat, idx) => ({
      name: cat,
      order: existingOrderedCategories.length + idx
    }))

  orderToUse = [...orderToUse, ...newCategories]
  orderToUse = orderToUse.filter((item) => result.data[categoryKey].categories.includes(item.name))

  // Create the reordered data structure
  return {
    ...result,
    data: {
      [categoryKey]: {
        categories: orderToUse.map((item) => item.name),
        data: result.data[categoryKey].data.map((series) => ({
          ...series,
          data: orderToUse.map((item) => {
            const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
            return series.data[originalIndex]
          })
        }))
      }
    },
    categoryOrder: orderToUse
  }
}

/**
 * Sorts the average span of control data based on a given category order
 *
 * @param {Object} result - The original data result from the API
 * @param {String} categoryKey - The category key to sort by
 * @param {Array} newOrder - The desired order for categories (optional)
 * @returns {Object} - The sorted data
 */
export function sortAverageSpanOfControlData(result, categoryKey, newOrder) {
  // If no new order is provided, use the existing order from the result
  let orderToUse = newOrder || result.categoryOrder

  if (!orderToUse?.length || !categoryKey || !result.data[categoryKey]) {
    return result
  }

  // First, ensure all categories from the result are represented in the order
  const existingOrderedCategories = orderToUse.map((item) => item.name)
  const newCategories = result.data[categoryKey].categories
    .filter((cat) => !existingOrderedCategories.includes(cat))
    .map((cat, idx) => ({
      name: cat,
      order: existingOrderedCategories.length + idx
    }))

  orderToUse = [...orderToUse, ...newCategories]
  orderToUse = orderToUse.filter((item) => result.data[categoryKey].categories.includes(item.name))

  // Create the reordered data structure
  return {
    ...result,
    data: {
      [categoryKey]: {
        categories: orderToUse.map((item) => item.name),
        data: result.data[categoryKey].data.map((series) => ({
          ...series,
          data: orderToUse.map((item) => {
            const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
            return series.data[originalIndex]
          })
        })),
        planData: result.data[categoryKey].planData.map((series) => ({
          ...series,
          data: orderToUse.map((item) => {
            const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
            return series.data[originalIndex]
          })
        }))
      }
    },
    categoryOrder: orderToUse
  }
}

/**
 * Sorts the stacked headcount cost data based on a given category order
 *
 * @param {Object} result - The original data result from the API
 * @param {String} categoryKey - The category key to sort by
 * @param {Array} newOrder - The desired order for categories (optional)
 * @returns {Object} - The sorted data
 */
export function sortStackedHeadcountCostData(result, categoryKey, newOrder) {
  // If no new order is provided, use the existing order from the result
  let orderToUse = newOrder || result.categoryOrder

  if (!orderToUse?.length || !categoryKey || !result.data[categoryKey]) {
    return result
  }

  // First, ensure all categories from the result are represented in the order
  const existingOrderedCategories = orderToUse.map((item) => item.name)
  const newCategories = result.data[categoryKey].categories
    .filter((cat) => !existingOrderedCategories.includes(cat))
    .map((cat, idx) => ({
      name: cat,
      order: existingOrderedCategories.length + idx
    }))

  orderToUse = [...orderToUse, ...newCategories]
  orderToUse = orderToUse.filter((item) => result.data[categoryKey].categories.includes(item.name))

  // Create the reordered data structure
  return {
    ...result,
    data: {
      [categoryKey]: {
        categories: orderToUse.map((item) => item.name),
        data: result.data[categoryKey].data.map((series) => ({
          ...series,
          data: orderToUse.map((item) => {
            const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
            return series.data[originalIndex]
          })
        })),
        planData: result.data[categoryKey].planData.map((series) => ({
          ...series,
          planData: orderToUse.map((item) => {
            const originalIndex = result.data[categoryKey].categories.indexOf(item.name)
            return series.data[originalIndex]
          })
        })),
        exportableData: result.data[categoryKey].exportableData
      }
    },
    categoryOrder: orderToUse
  }
}

/**
 * Sorts the rows in the layers table data based on a given order
 *
 * @param {Object} result - The original data result from the API
 * @param {Array} newOrder - The desired order for rows
 * @returns {Object} - The data with rows sorted according to the specified order
 */
export function sortLayersTableRows(result, newOrder) {
  // Use the provided order or fall back to the existing category order
  const rowOrderToUse = newOrder || result.categoryOrder || []

  if (!rowOrderToUse?.length || !result?.data) {
    return result
  }

  // Create a map of level name to data for quick lookup
  const dataMap = {}
  Object.entries(result.data).forEach(([key, value]) => {
    dataMap[key] = value
  })

  // Create ordered data object based on the provided order
  const orderedData = {}
  rowOrderToUse.forEach(({ name }) => {
    if (dataMap[name]) {
      orderedData[name] = dataMap[name]
    }
  })

  // Add any missing categories that weren't in the order
  Object.keys(result.data).forEach((key) => {
    if (!orderedData[key]) {
      orderedData[key] = result.data[key]
    }
  })

  // Return the result with reordered data
  return {
    ...result,
    data: orderedData,
    categoryOrder: rowOrderToUse
  }
}

/**
 * Sorts the columns in the layers table data based on a given order
 *
 * @param {Object} result - The original data result from the API
 * @param {Array} columnOrder - The desired order for columns
 * @returns {Object} - The data with columns sorted according to the specified order
 */
export function sortLayersTableColumns(result, columnOrder) {
  if (!columnOrder?.length || !result?.data) {
    return result
  }

  // Create a mapping between display names and data keys
  const columnNameToDataKey = {
    'Average IC salary': 'AVG_IC_SALARY',
    'Average manager salary': 'AVG_MANAGER_SALARY',
    'Average salary': 'AVG_SALARY',
    'Average SoC': 'AVG_SOC',
    Headcount: 'NUM_HEADCOUNT',
    'IC cost': 'IC_COST',
    'IC count': 'NUM_ICS',
    'Manager cost': 'MANAGER_COST',
    'Manager count': 'NUM_MANAGERS',
    'Manager/IC ratio': 'MANAGER_IC_RATIO',
    'Total headcount cost': 'TOTAL_HEADCOUNT_COST'
  }

  // Get data keys in the desired order
  const orderedDataKeys = columnOrder.map((col) => columnNameToDataKey[col.name]).filter(Boolean)

  // Create a new data object with reordered columns
  const dataWithReorderedColumns = {}

  // For each layer (row)
  Object.entries(result.data).forEach(([layerKey, layerData]) => {
    // Create a new layer object
    const reorderedLayer = {}

    // Add columns in the desired order
    orderedDataKeys.forEach((dataKey) => {
      if (layerData[dataKey] !== undefined) {
        reorderedLayer[dataKey] = layerData[dataKey]
      }
    })

    // Add any remaining properties that weren't in our mapping
    Object.entries(layerData).forEach(([key, value]) => {
      if (reorderedLayer[key] === undefined) {
        reorderedLayer[key] = value
      }
    })

    dataWithReorderedColumns[layerKey] = reorderedLayer
  })

  // Return the result with reordered columns
  return {
    ...result,
    data: dataWithReorderedColumns,
    columnOrder: columnOrder,
    displayColumnOrder: columnOrder.map((col) => col.name)
  }
}

/**
 * Reorders the series data in the layer and span of control chart based on a given order
 *
 * @param {Object} result - The original data result
 * @param {Array} newOrder - The desired order for the y-axis (layers)
 * @returns {Object} - The data with series reordered according to the specified order
 */
export function reorderLayerAndSpanOfControlYAxis(result, newOrder) {
  let orderToUse = newOrder || result.categoryOrder
  // Create a modified result object that we'll update
  let modifiedResult = { ...result }

  // Handle Y-axis ordering (series order)
  if (orderToUse?.length && result.data?.default) {
    // Create a map of layer name to series for quick lookup
    const seriesMap = result.data.default.reduce((acc, series) => {
      acc[series.name] = series
      return acc
    }, {})
    // Reorder the series based on the new order
    const reorderedSeries = orderToUse.map((item) => seriesMap[item.name]).filter(Boolean)
    modifiedResult = {
      ...modifiedResult,
      data: {
        ...modifiedResult.data,
        default: reorderedSeries
      },
      categoryOrder: orderToUse
    }
  }

  return modifiedResult
}

/**
 * Reorders the data points within each series in the layer and span of control chart based on a given x-axis order
 *
 * @param {Object} result - The original data result
 * @param {Array} xAxisOrder - The desired order for the x-axis (span of control groups)
 * @returns {Object} - The data with data points reordered according to the specified order
 */
export function reorderLayerAndSpanOfControlXAxis(result, xAxisOrder) {
  let xAxisOrderToUse = xAxisOrder || result.xAxisOrder
  let modifiedResult = { ...result }

  // If xAxisOrderToUse is missing or empty, create a default one from the data
  if (!xAxisOrderToUse || xAxisOrderToUse.length === 0) {
    // Get all X values from the data
    const allXValues = new Set()
    if (modifiedResult.data?.default) {
      modifiedResult.data.default.forEach((series) => {
        if (series.data) {
          series.data.forEach((point) => {
            if (point?.x) {
              allXValues.add(point.x)
            }
          })
        }
      })
    }

    // Convert to array and create order objects
    xAxisOrderToUse = Array.from(allXValues).map((value, index) => ({
      name: value,
      order: index
    }))
  }

  // If xAxisOrderToUse exists but data points don't match, remap them
  if (xAxisOrderToUse && modifiedResult.data?.default) {
    const seriesData = modifiedResult.data.default

    // Get all X values from the data
    const allXValues = new Set()
    seriesData.forEach((series) => {
      if (series.data) {
        series.data.forEach((point) => {
          if (point?.x) {
            allXValues.add(point.x)
          }
        })
      }
    })

    // Create a set of names in xAxisOrderToUse for quick lookup
    const existingNames = new Set(xAxisOrderToUse.map((item) => item.name))

    // Find values in data that are not in xAxisOrderToUse
    const missingValues = Array.from(allXValues).filter((value) => !existingNames.has(value))

    // Add missing values to xAxisOrderToUse
    if (missingValues.length > 0) {
      // Store the original order for reference
      const originalOrder = [...xAxisOrderToUse]

      // Add missing values at the same positions as their similar values
      missingValues.forEach((missingValue) => {
        // Default to adding at the end
        let insertPosition = xAxisOrderToUse.length
        let insertOrder =
          xAxisOrderToUse.length > 0
            ? Math.max(...xAxisOrderToUse.map((item) => item.order)) + 1
            : 0

        // Try to find a similar value in the original order
        for (let i = 0; i < originalOrder.length; i++) {
          const originalName = originalOrder[i].name

          // Check if the missing value is similar to this original value
          // This is a simple check - you might need to adjust based on your naming patterns
          if (originalName.charAt(0) === missingValue.charAt(0)) {
            // Insert at this position
            insertPosition = i
            insertOrder = originalOrder[i].order
            break
          }
        }

        // Insert the missing value at the determined position
        xAxisOrderToUse.splice(insertPosition, 0, {
          name: missingValue,
          order: insertOrder
        })
      })

      // Re-sort by order
      xAxisOrderToUse.sort((a, b) => a.order - b.order)
    }

    // For each series, reorder the data points based on updated xAxisOrder
    const reorderedSeriesData = seriesData.map((series) => {
      if (!series.data) return series

      // Create a map of x values to data points for quick lookup
      const dataMap = series.data.reduce((acc, point) => {
        acc[point.x] = point
        return acc
      }, {})

      // Create the reordered data array
      const reorderedData = xAxisOrderToUse.map((item) => dataMap[item.name]).filter(Boolean) // Filter out any undefined values

      return {
        ...series,
        data: reorderedData
      }
    })

    // Update the result with reordered series data
    modifiedResult = {
      ...modifiedResult,
      data: {
        ...modifiedResult.data,
        default: reorderedSeriesData
      },
      xAxisOrder: xAxisOrderToUse
    }
  }

  return modifiedResult
}

/**
 * Reorders the series data in the layer and depth chart based on a given y-axis order
 *
 * @param {Object} result - The original data result from the API
 * @param {Array} newOrder - The desired order for the y-axis categories
 * @returns {Object} - The sorted data
 */
export function sortLayerAndDepthYAxis(result, newOrder) {
  // If no result or no order provided, return the original result
  if (!result || !newOrder?.length) {
    return result
  }

  // Find the data key (e.g., "orgLevel_orgLevel")
  const dataKey = Object.keys(result.data)[0]
  if (!dataKey || !result.data[dataKey]?.data) {
    return result
  }

  // Get the series data
  const seriesData = result.data[dataKey].data

  // Create a map of series name to series for quick lookup
  const seriesMap = {}
  seriesData.forEach((series) => {
    if (series?.name) {
      seriesMap[series.name] = series
    }
  })

  // Reorder the series based on the new order
  const reorderedSeries = []
  newOrder.forEach((item) => {
    if (seriesMap[item.name]) {
      reorderedSeries.push(seriesMap[item.name])
    }
  })

  // Add any series that weren't in the order
  seriesData.forEach((series) => {
    if (!newOrder.some((item) => item.name === series.name)) {
      reorderedSeries.push(series)
    }
  })

  // Update the result with the reordered series
  result.data[dataKey].data = reorderedSeries
  result.data[dataKey].categoryOrder = newOrder

  return result
}

/**
 * Reorders the data points within each series in the layer and depth chart based on a given x-axis order
 *
 * @param {Object} result - The original data result from the API
 * @param {Array} xAxisOrder - The desired order for the x-axis categories
 * @returns {Object} - The sorted data
 */
export function sortLayerAndDepthXAxis(result, xAxisOrder) {
  // If no result or no order provided, return the original result
  if (!result || !xAxisOrder?.length) {
    return result
  }

  // Find the data key (e.g., "orgLevel_orgLevel")
  const dataKey = Object.keys(result.data)[0]
  if (!dataKey || !result.data[dataKey]?.data) {
    return result
  }

  // Get the series data
  const seriesData = result.data[dataKey].data

  // For each series, reorder the data points based on xAxisOrder
  seriesData.forEach((series) => {
    if (series?.data) {
      // Create a map of x values to data points for quick lookup
      const dataMap = {}
      series.data.forEach((point) => {
        if (point?.x) {
          dataMap[point.x] = point
        }
      })

      // Create the reordered data array
      const reorderedData = []
      xAxisOrder.forEach((item) => {
        if (dataMap[item.name]) {
          reorderedData.push(dataMap[item.name])
        }
      })

      // Update the series data with the reordered data
      series.data = reorderedData
    }
  })

  // Update the result with the x-axis order
  result.data[dataKey].xAxisOrder = xAxisOrder

  return result
}

/**
 * Reorders the series data in the skills heatmap chart based on a given y-axis order (roles)
 *
 * @param {Object} result - The original data result from the API
 * @param {Array} newOrder - The desired order for the y-axis categories (roles)
 * @returns {Object} - The sorted data
 */
export function sortSkillsHeatmapYAxis(result, newOrder) {
  // If no result or no order provided, return the original result
  if (!result || !newOrder?.length) {
    return result
  }
  // Create a deep copy of the result to avoid modifying the original
  const modifiedResult = JSON.parse(JSON.stringify(result))

  // Check if we have data in the expected structure
  if (!modifiedResult.dataByOptions && !modifiedResult.data) {
    return modifiedResult
  }

  // Determine which property contains our data
  const dataProperty = modifiedResult.dataByOptions ? 'dataByOptions' : 'data'

  // Get all keys from the original data
  const allKeys = Object.keys(modifiedResult[dataProperty] || {})

  // Create a new ordered data object
  const orderedData = {}

  // First add items in the specified order
  newOrder.forEach((item) => {
    const roleName = item.name
    if (modifiedResult[dataProperty]?.[roleName]) {
      orderedData[roleName] = modifiedResult[dataProperty][roleName]
    }
  })

  // Then add any remaining items
  allKeys.forEach((key) => {
    if (!orderedData[key]) {
      orderedData[key] = modifiedResult[dataProperty][key]
    }
  })

  // Replace the data with the ordered data
  modifiedResult[dataProperty] = orderedData

  // Also update the options array to match the new order
  if (modifiedResult.options && Array.isArray(modifiedResult.options)) {
    const orderedOptions = []

    // First add options in the specified order
    newOrder.forEach((item) => {
      const roleName = item.name
      if (modifiedResult.options.includes(roleName)) {
        orderedOptions.push(roleName)
      }
    })

    // Then add any remaining options
    modifiedResult.options.forEach((option) => {
      if (!orderedOptions.includes(option)) {
        orderedOptions.push(option)
      }
    })

    modifiedResult.options = orderedOptions
  }

  // Store the category order
  modifiedResult.categoryOrder = newOrder.map((item) => ({ name: item.name }))
  return modifiedResult
}

/**
 * Reorders the data points within each series in the skills heatmap chart based on a given x-axis order (people)
 *
 * @param {Object} result - The original data result from the API
 * @param {Array} xAxisOrder - The desired order for the x-axis (people)
 * @returns {Object} - The data with data points reordered according to the specified order
 */
export function sortSkillsHeatmapXAxis(result, xAxisOrder) {
  // If no result or no order provided, return the original result
  if (!result || !xAxisOrder?.length) {
    return result
  }

  // Create a deep copy of the result to avoid modifying the original
  const modifiedResult = JSON.parse(JSON.stringify(result))

  // Check if we have data in the expected structure
  if (!modifiedResult.dataByOptions && !modifiedResult.data) {
    return modifiedResult
  }

  // Determine which property contains our data
  const dataProperty = modifiedResult.dataByOptions ? 'dataByOptions' : 'data'

  // For each role, reorder the data points
  Object.keys(modifiedResult[dataProperty] || {}).forEach((roleName) => {
    const roleData = modifiedResult[dataProperty][roleName]

    if (Array.isArray(roleData)) {
      // Create a map of person name to data point for quick lookup
      const dataPointMap = {}
      roleData.forEach((point) => {
        if (point?.x) {
          dataPointMap[point.x] = point
        }
      })

      // Create a new ordered array
      const orderedData = []

      // First add data points in the specified order
      xAxisOrder.forEach((item) => {
        const personName = item.name
        if (dataPointMap[personName]) {
          orderedData.push(dataPointMap[personName])
          delete dataPointMap[personName] // Remove to avoid duplicates
        }
      })

      // Then add any remaining data points
      Object.values(dataPointMap).forEach((point) => {
        orderedData.push(point)
      })

      // Update the role data with the reordered points
      modifiedResult[dataProperty][roleName] = orderedData
    }
  })

  // Store the x-axis order
  modifiedResult.xAxisOrder = xAxisOrder.map((item) => ({ name: item.name }))

  return modifiedResult
}
