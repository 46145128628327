import pluralizeVendor from 'pluralize'

/**
 * Pluralizes a given word based on the provided count.
 * Relies on a vendor function `pluralizeVendor` to perform the actual pluralization.
 *
 * @param {string} word - The word to be pluralized.
 * @param {number} count - The count determining whether the word should be singular or plural.
 * @returns {string} - The pluralized form of the word if count is not 1, otherwise the singular form.
 */
export const pluralize = (word, count) => {
  return pluralizeVendor(word, count)
}

/**
 * Converts a given string to camelCase.
 *
 * @param {string} text - The string to be converted to camelCase.
 * @returns {string} - The camelCase version of the input string.
 */
export const toCamelCase = (text) => {
  return (
    text
      // Trim the text
      .trim()
      // Replace sequences of spaces with a single space
      .replace(/\s+/g, ' ')
      // Convert to camelCase
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (Number(match) === 0) return '' // or if (/\s+/.test(match)) for clarity
        return index === 0 ? match.toLowerCase() : match.toUpperCase()
      })
      .replace(/\s/g, '')
  ) // Finally, remove any spaces left
}

/**
 * Converts a given camelCase string to "Title Case with spaces" (e.g., `employeeNumber` -> `Employee Number`).
 * If an array of strings is provided, it processes each string in the array.
 *
 * @param {string | string[]} input - The string or array of strings to be converted.
 * @returns {string | string[]} - The formatted string or array of formatted strings.
 */
export function toTitleWithSpaces(input) {
  // Helper function to convert camelCase to Title Case with spaces
  const formatToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
      .replace(/^./, (s) => s.toUpperCase()) // Capitalize the first letter
      .trim() // Remove leading/trailing spaces
  }

  if (Array.isArray(input)) {
    // Process each string in the array
    return input.map((item) => formatToTitleCase(item))
  }

  if (typeof input === 'string') {
    return formatToTitleCase(input)
  }

  throw new TypeError('Input must be a string or an array of strings')
}

/**
 * Function to highlight the searched text
 * @param text the complete text a part of which is to be highlighted
 * @param query the searched text that has to be highlighted
 * @return the final text with a highlighted part
 */
export const highlightText = (text, query) => {
  const matchedIndex = text.toLowerCase().indexOf(query.toLowerCase())

  if (query.length && matchedIndex !== -1) {
    const beforeMarked = text.slice(0, matchedIndex)
    const marked = text.slice(matchedIndex, matchedIndex + query.length)
    const afterMarked = text.slice(matchedIndex + query.length)
    text = `${beforeMarked}<mark class="bg-primary-200 text-inherit">${marked}</mark>${afterMarked}`
  }

  return `<span class="capitalize">${text}</span>`
}
