import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export async function getRateCardMapping({ boardId }) {
  // GET /settings/:boardId/rate-card-mapping
  try {
    const response = await axios.get(`${API_URL}/settings/${boardId}/rate-card-mapping`, {
      headers: { ...(await getAuthHeader()) }
    })

    return response?.data
  } catch (error) {
    console.error('Error fetching rate card mapping:', error)
    return null
  }
}

export async function getRateCardMappingRow({ boardId, attributeColumnValues }) {
  // GET /settings/:boardId/rate-card-mapping-row
  try {
    const response = await axios.get(`${API_URL}/settings/${boardId}/rate-card-mapping-row`, {
      params: { attributeColumnValues },
      headers: { ...(await getAuthHeader()) }
    })

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    if (error.response?.status !== 404) {
      console.error('Error fetching rate card mapping row:', error)
    }
    return error.response || error
  }
}

export async function getRateCardMappingShortlist({
  boardId,
  shortlistBy,
  rateCardAttributeColumns
}) {
  // GET /settings/:boardId/rate-card-mapping-shortlist
  try {
    const response = await axios.get(`${API_URL}/settings/${boardId}/rate-card-mapping-shortlist`, {
      params: { shortlistBy, rateCardAttributeColumns },
      headers: { ...(await getAuthHeader()) }
    })

    return response?.data || {}
  } catch (error) {
    console.error('Error fetching rate card mapping shortlist:', error)
    return null
  }
}

export async function getSettings({ boardId }) {
  // GET /settings/:boardId
  try {
    const ret = await axios.get(`${API_URL}/settings/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return {}
  }
}

export async function updateSettings({ boardId, settings }) {
  try {
    const ret = await axios.post(
      `${API_URL}/settings/${boardId}`,
      { data: { settings } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200 || ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function flushCache({ boardId }) {
  try {
    console.log('Flushing cache... BoardId:', boardId)
    const ret = await axios.post(`${API_URL}/settings/flush-cache`,
      { boardId },
      { headers: { ...(await getAuthHeader()) } })

    if (ret.status === 200) {
      console.log('Cache flushed successfully')
      return ret.data
    } else {
      console.error('Failed to flush cache')
      throw new Error(ret.statusText)
    }
  } catch (error) {
    console.error('Error flushing cache:', error)
    return null
  }
}
