import axios from 'axios'
import { API_URL, getAuthHeader } from './api.service'

/**
 * Retrieves operating model data for a given board and plan.
 *
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.boardId - The ID of the board to retrieve operating model data for.
 * @param {string} params.planId - The ID of the plan to retrieve operating model data for.
 * @returns {Object|null} - The operating model data if successful, or null if an error occurred.
 */
export async function getOperatingModel({ boardId, planId }) {
  try {
    const ret = await axios.get(`${API_URL}/operating-model/${boardId}/${planId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      if (ret.data === '') {
        return {}
      }
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Saves the operating model for a given board and plan.
 *
 * @param {Object} options - The options object.
 * @param {string} options.boardId - The ID of the board.
 * @param {string} options.planId - The ID of the plan.
 * @param {Object} options.entry - The operating model data to be saved.
 * @returns {Object|null} - The operating model data if successful, or null if an error occurred.
 */
export async function saveOperatingModel({ boardId, planId, entry }) {
  try {
    const ret = await axios.post(
      `${API_URL}/operating-model/${boardId}/${planId}`,
      { data: { entry } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Duplicates the activity analysis for a given board and plan.
 *
 * @param {Object} options - The options object.
 * @param {string} options.boardId - The ID of the board.
 * @param {string} options.planId - The ID of the plan.
 * @returns {Object|null} - The activity analysis data if successful, or null if an error occurred.
 */
export async function duplicateActivityAnalysis({ boardId, planId, newPlanId }) {
  try {
    const ret = await axios.post(
      `${API_URL}/operating-model/${boardId}/${planId}/duplicate`,
      { data: { newPlanId } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Deletes the activity analysis for a given board and plan.
 *
 * @param {Object} options - The options object.
 * @param {string} options.boardId - The ID of the board.
 * @param {string} options.planId - The ID of the plan.
 */
export async function deleteActivityAnalysis({ boardId, planId }) {
  try {
    const ret = await axios.delete(`${API_URL}/operating-model/${boardId}/${planId}/delete`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
