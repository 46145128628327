<template>
  <div class="left-4 top-[130px] flex w-full flex-shrink-0 items-center">
    <section
      v-if="
        isAppLoading || isFetchingBoards || boards.length > 0 || hasPlanInvites || hasInvitations
      "
      class="mr-4 flex h-9 items-center rounded-lg bg-agentnoon-primary-700 p-1 pr-1.5 shadow-lg"
    >
      <p class="ml-1.5 mr-2 text-sm text-white">Instance</p>
      <BoardSelection />
    </section>

    <div class="relative flex-1">
      <PopperTooltip
        v-if="isAgentnoonAccount"
        content="Add instance"
        placement="bottom"
        hover
        open-delay="300"
        :disabled="showInstanceOptions"
        style="border-width: 0; margin: 0"
      >
        <button
          v-if="isAgentnoonAccount"
          class="instance-options-toggle flex items-center justify-center text-sm font-medium text-gray-500 transition-colors hover:text-bluedark-700"
          @click="toggleInstanceOptions"
        >
          <div
            class="flex h-7 w-7 items-center justify-center rounded-full bg-agentnoon-primary-700"
          >
            <PlusIcon class="h-4 w-4 text-white" />
          </div>
        </button>
      </PopperTooltip>
      <ul
        v-if="showInstanceOptions"
        ref="optionsMenu"
        class="absolute left-0 top-full mt-2 rounded-lg border-gray-200 bg-white text-left text-sm shadow-md"
      >
        <li class="w-full">
          <button
            class="group relative flex w-full flex-col gap-4 whitespace-nowrap rounded-lg px-4 py-2.5 text-left hover:bg-gray-50"
            @click="showCreateInstanceModal = true"
          >
            <p class="text-xs text-gray-900">Create new instance</p>
          </button>
        </li>
        <li class="w-full">
          <button
            class="group relative flex w-full flex-col gap-4 whitespace-nowrap rounded-lg px-4 py-2.5 text-left hover:bg-gray-50"
            @click="showCloneInstanceModal = true"
          >
            <p class="text-xs text-gray-900">Clone existing instance</p>
          </button>
        </li>
      </ul>
    </div>

    <CreateBoard :show="showCreateInstanceModal" @close="showCreateInstanceModal = false" />
    <CloneInstance :show="showCloneInstanceModal" @close="showCloneInstanceModal = false" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import BoardSelection from '@/components/BoardSelection.vue'
import CloneInstance from '@/components/CloneInstance.vue'
import CreateBoard from './CreateBoard.vue'
import { onClickOutside } from '@vueuse/core'
import { PlusIcon } from '@heroicons/vue/outline'
import useBoard from '@/hooks/use-board'

const { getters } = useStore()
const { boards } = useBoard()

const showCreateInstanceModal = ref(false)
const showCloneInstanceModal = ref(false)
const showInstanceOptions = ref(false)
const optionsMenu = ref(null)

const user = computed(() => getters.user)
const isAgentnoonAccount = computed(() => {
  return (
    user.value.email.includes('@agentnoon.com') ||
    user.value.email.includes('@alignorg.com') ||
    user.value.email.includes('@agentnoon.onmicrosoft.com') ||
    user.value.email === 'agentnoon+test@csisolar.co' ||
    user.value.email === 'sanjeev.tiwari@es.nestle.com'
  )
})
const isAppLoading = computed(() => getters.isAppLoading)
const isFetchingBoards = computed(() => getters.isFetchingBoards)
const hasInvitations = computed(() => getters.hasPendingInvitations)
const hasPlanInvites = computed(() => getters.hasUserPlanInvites)

onClickOutside(optionsMenu, (event) => {
  // Only close if we're not clicking the toggle button
  if (!event.target.closest('button.instance-options-toggle')) {
    showInstanceOptions.value = false
  }
})

const toggleInstanceOptions = () => {
  showInstanceOptions.value = !showInstanceOptions.value
}
</script>
