<template>
  <div ref="menu" class="relative">
    <PopperTooltip content="Boards" placement="bottom" hover open-delay="300">
      <button
        class="flex h-9 items-center gap-2 overflow-hidden truncate rounded-lg bg-agentnoon-primary-700 px-2 text-sm font-medium text-white focus:outline-none"
        @click="isMenuOpen = !isMenuOpen"
      >
        <span v-if="project && project.planBoardId === boardId">{{ project.name }}</span>
        <span v-else>{{ board?.boardName ?? '' }}</span>

        <MainBoardPill v-if="boardId === project?.planBoardId || boardId === mainBoardId" />

        <ChevronDownIcon
          class="mr-1 h-5 w-5 transition-transform"
          :class="{ 'rotate-180 transform': isMenuOpen }"
        />
      </button>
    </PopperTooltip>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="absolute left-0 z-[60] mt-2 w-[320px] rounded-lg border bg-white shadow-lg"
      >
        <button
          class="flex w-full items-center justify-between border-b px-4 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-100"
          @click="navigateToMainBoard()"
        >
          {{ project?.name ?? mainBoard?.boardName }}
          <MainBoardPill />
        </button>
        <!-- Search Bar -->
        <div class="p-2">
          <div class="relative">
            <input
              ref="filterInput"
              v-model="searchQuery"
              class="focus:ring-agentnoon-primary-500 w-full rounded-lg border border-gray-300 py-2 pl-10 pr-3 text-sm font-normal text-gray-900 placeholder:text-gray-500 focus:border-agentnoon-primary-700 focus:outline-none focus:ring-1"
              type="search"
              placeholder="Search"
            />
            <SearchIcon class="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-500" />
          </div>
        </div>

        <!-- Agentnoon Section -->
        <div
          v-if="snapshotScenarios && snapshotScenarios.length > 0"
          class="group/menu relative cursor-pointer px-2 py-1"
          @mouseenter="showNestedDropdown = true"
          @mouseleave="showNestedDropdown = false"
        >
          <div class="flex w-full items-center justify-between rounded-lg p-3 hover:bg-gray-50/75">
            <div class="flex items-center gap-2">
              <span class="text-base font-medium text-gray-900 group-hover/menu:text-blue-600">
                Agentnoon
              </span>
            </div>
            <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover/menu:text-blue-600" />
          </div>

          <!-- Safe area for mouse movement -->
          <div
            class="absolute -right-2 top-0 hidden h-full w-2 group-hover/menu:block"
            @mouseenter="showNestedDropdown = true"
          />

          <!-- Right Panel (Nested Dropdown) -->
          <div
            v-if="showNestedDropdown"
            class="absolute -right-[420px] top-0 w-[420px] overflow-hidden rounded-lg border bg-white shadow-lg"
            @mouseenter="showNestedDropdown = true"
          >
            <div class="max-h-[420px] overflow-y-auto">
              <div v-for="(scenario, index) in snapshotScenarios" :key="index">
                <button
                  class="group flex w-full items-center justify-between rounded-lg px-4 py-3 text-left hover:bg-gray-50"
                  @click="navigateToPlan(scenario.boardId)"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="flex-1">
                      <span class="text-base font-normal text-gray-900 group-hover:hidden">
                        {{ scenario.boardName }}
                      </span>
                      <span class="text-md hidden font-medium text-blue-600 group-hover:block">
                        {{ scenario.boardName }} - {{ formatDate(scenario.createdAt) }}
                      </span>
                    </div>
                    <div class="flex items-center gap-3">
                      <span
                        class="rounded-md bg-gray-50 px-3 py-1 text-sm text-gray-500 group-hover:hidden"
                      >
                        {{ formatDate(scenario.createdAt) }}
                      </span>
                      <button
                        class="hidden rounded-md bg-bluedark-50 px-4 py-1 text-sm font-medium text-blue-600 group-hover:block"
                      >
                        View
                      </button>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Scenarios List -->
        <div class="max-h-[50vh] overflow-y-auto pb-2">
          <div v-for="(scenario, index) in filteredScenarios" :key="index" class="px-2 py-1">
            <div class="w-full rounded-lg p-3 hover:bg-gray-50/75">
              <button
                class="w-full text-left text-gray-900"
                @click="navigateToPlan(scenario.boardId)"
              >
                {{ scenario.boardName }}
              </button>
            </div>
          </div>
        </div>

        <!-- See All Button -->
        <div>
          <router-link
            :to="{
              name: 'Plans',
              params: { boardId: mainBoardId, projectId }
            }"
            class="mx-2 my-2 block rounded-md bg-bluedark-50 px-4 py-2.5 text-center font-medium text-agentnoon-primary-700 hover:bg-bluedark-100"
            @click="isMenuOpen = false"
          >
            See all
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import useBoard from '@/hooks/use-board'
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/outline'
import { onClickOutside, useMagicKeys } from '@vueuse/core'
import { useRouter } from 'vue-router'
import SearchIcon from '@/assets/untitled-ui/search.svg'
import { useProjects } from '@/hooks/use-projects'
import MainBoardPill from '@/components/MainBoardPill.vue'

const props = defineProps({
  boardId: {
    type: String,
    required: true
  },
  projectId: {
    type: String,
    required: false,
    default: null
  }
})

const menu = ref(null)
const isMenuOpen = ref(false)
const searchQuery = ref('')
const showNestedDropdown = ref(false)
const windowHeight = ref(window.innerHeight)

const handleResize = () => {
  windowHeight.value = window.innerHeight
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.key === 'Escape' && e.type === 'keydown') {
      isMenuOpen.value = false
      searchQuery.value = ''
    }
  }
})

const { getters } = useStore()
const { boardId: mainBoardId } = useBoard()
const { push } = useRouter()
const { projects } = useProjects(mainBoardId)

const project = computed(() =>
  props.projectId ? projects.value?.find((project) => project.id === props.projectId) : null
)

const mainProjectBoard = computed(() => getters.getBoard(project.value?.planBoardId))
const board = computed(() => getters.getBoard(props.boardId))
const mainBoard = computed(() => getters.getBoard(mainBoardId.value))

const scenarios = computed(() => {
  const allScenarios = getters.scenarios(mainBoardId.value)
  if (props.projectId) {
    return allScenarios.filter(
      (scenario) =>
        scenario.projectId === props.projectId &&
        scenario.boardId !== mainProjectBoard.value?.boardId
    )
  }

  return allScenarios.filter((scenario) => !scenario.projectId)
})

const navigateToMainBoard = async () => {
  if (props.projectId) {
    await push({
      name: 'Plan View',
      params: { boardId: mainBoardId.value, planId: project.value?.planBoardId }
    })
  } else {
    await push({
      name: 'Board',
      params: { boardId: mainBoardId.value }
    })
  }
}

const filterBySearch = (scenarios) => {
  if (!searchQuery.value) return scenarios
  return scenarios.filter((scenario) =>
    scenario.boardName.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
}

const snapshotScenarios = computed(() => {
  const snapshots = scenarios.value.filter((scenario) => scenario.isSnapshot)
  const filtered = filterBySearch(snapshots)
  return filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
})

const filteredScenarios = computed(() => {
  const nonSnapshots = scenarios.value.filter((scenario) => !scenario.isSnapshot)
  const filtered = filterBySearch(nonSnapshots)
  return filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
})

const formatDate = (date) => {
  if (!date) return ''
  return new Date(date)
    .toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    .replace(/\//g, '-')
}

const navigateToPlan = (planId) => {
  push({
    name: 'Plan View',
    params: { boardId: mainBoardId.value, planId }
  })
}

onClickOutside(menu, () => {
  isMenuOpen.value = false
  searchQuery.value = ''
  showNestedDropdown.value = false
})
</script>

<style scoped>
.etihad-color {
  background-color: #cda75a;
  color: white;
}
</style>
