export const PPTLayoutOptions = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  COMPACT: 'compact'
}

export const PPTLayoutLabels = {
    [PPTLayoutOptions.HORIZONTAL]: 'H',
    [PPTLayoutOptions.VERTICAL]: 'V',
    [PPTLayoutOptions.COMPACT]: 'C'
  }
  
export const PPTLevelsPerSlide = {
  TWO: '2',
  THREE: '3'
}

export const PPTLevelsLabels = {
  [PPTLevelsPerSlide.TWO]: '2/Slide',
  [PPTLevelsPerSlide.THREE]: '3/Slide'
}

export const PPTCardContentOptions = {
    FOCUSED: 'focused',
    CUSTOM: 'custom'
  }
  
  export const PPTCardContentLabels = {
    [PPTCardContentOptions.FOCUSED]: {
      label: 'Focused',
      description: 'Job titles only'
    },
    [PPTCardContentOptions.CUSTOM]: {
      label: 'Custom',
      description: 'App selections'
    }
  }
