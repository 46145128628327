<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" class="relative z-[100]" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                Clone existing instance
              </DialogTitle>
              <div class="mt-1">
                <p class="text-sm text-gray-500">This is visible to Agentnoon Admins Only.</p>
                <div class="mt-4 flex flex-col gap-2">
                  <div class="relative">
                    <label for="instance" class="mb-2 block text-xs font-medium text-gray-900">
                      Select instance
                    </label>
                    <FilterListbox
                      :value="selectedBoard?.name || ''"
                      :list="boardOptions"
                      :show-search="true"
                      options-height="max-h-[30vh]"
                      @update="handleBoardSelection"
                    />
                  </div>
                  <div>
                    <label for="name" class="mb-2 block text-xs font-medium text-gray-900">
                      Company name
                    </label>
                    <input
                      id="name"
                      v-model="companyName"
                      type="text"
                      name="name"
                      class="border-1 peer mt-1 block w-full rounded-md border-gray-300 bg-white px-3.5 py-2.5 text-base text-gray-900 placeholder-slate-400 shadow-sm focus:border-bluedark-300 focus:outline-none focus:ring focus:ring-bluedark-100/50 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none"
                      placeholder="Acme Corp."
                    />
                  </div>
                </div>
              </div>

              <div class="mt-4 flex justify-end">
                <button
                  type="button"
                  class="bg-white-100 text-black-900 focus-visible:black-500 hover:bg-black-300 inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                  @click="$emit('close')"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  :disabled="inProgress || companyName.length === 0"
                  class="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50"
                  @click="handleCloneInstance"
                >
                  <SpinnerComp v-if="inProgress" class="mr-2 h-4 w-4" />
                  Create
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { ACCESS_LEVELS_DICT } from '@/lib/Access.js'
import SpinnerComp from '@/components/SpinnerComp.vue'

import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { cloneInstance } from '@/services/boards-api.service'

import { useRouter } from 'vue-router'
import { createBoardId } from '@/lib/idCreator'
import FilterListbox from '@/components/FilterListbox.vue'
import useBoard from '@/hooks/use-board'

const { push } = useRouter()

const store = useStore()
const { boardId } = useBoard()

const companyName = ref('')
const inProgress = ref(false)

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  }
})

defineEmits(['close'])

const selectedBoard = ref(null)

// Get boards where user is admin
const adminBoards = computed(() => {
  const uid = store.getters.user?.uid
  return store.getters.mainBoards.filter((board) => {
    const accessLevel = board.accessLevels?.find((level) => level.uid === uid)?.accessLevel
    return accessLevel === ACCESS_LEVELS_DICT.ADMIN
  })
})

// Use this as dropdown options
const boardOptions = computed(() => {
  return adminBoards.value.map((board) => ({
    id: board.boardId,
    name: board.boardName || 'Untitled Board'
  }))
})

// Reset and update selection when modal opens or boardId changes
watch(
  [() => props.show, boardId],
  ([isShown, newBoardId]) => {
    if (isShown && adminBoards.value.length > 0) {
      // Try to select current board if user is admin
      const currentBoard = adminBoards.value.find((board) => board.boardId === newBoardId)
      selectedBoard.value = currentBoard
        ? { id: currentBoard.boardId, name: currentBoard.boardName }
        : { id: adminBoards.value[0].boardId, name: adminBoards.value[0].boardName }
    }
  },
  { immediate: true }
)

/**
 * Function to set the selected board
 * @param {name} The selection from the handler function of dropdown
 */
const handleBoardSelection = async (selection) => {
  if (!selection.id) return
  if (selection.id === selectedBoard.value.id) return
  selectedBoard.value = selection
}

const handleCloneInstance = async () => {
  inProgress.value = true
  const boardName = companyName.value
  const _companyName = companyName.value
  const newBoardId = createBoardId()

  const payload = {
    boardId: newBoardId,
    boardName,
    srcBoardId: selectedBoard.value.id,
    companyName: _companyName
  }
  const ret = await cloneInstance({ payload })
  inProgress.value = false

  await push({
    name: 'Landing',
    params: { boardId: ret.boardId }
  })
  window.location.reload()
}
</script>
