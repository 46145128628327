/* eslint-disable no-unused-vars */
import { until, useDebounceFn, isObject, useWebWorker } from '@vueuse/core'
import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { decompressJson, decompressPeople, decompressBufferFflate } from '@/utils/Utils.js'
import { ref } from 'vue'
import { keyBy } from 'lodash-es'
import { DateTime } from 'luxon'
import DecompressWorker from '@/workers/decompress-json.js?worker'
import { PPTLayoutOptions } from '@/lib/PPTExport'

let hierarchyEtagDict = {} // XXX Do not initialize to null. Things don't work when you initialize to null!

// Initialize pendingRequests as a Map to track ongoing requests
const pendingRequests = new Map()

export async function getPeople({ boardId }) {
  const ret = await axios.get(`${API_URL}/people/${boardId}`, {
    responseType: 'arraybuffer',
    headers: {
      ...(await getAuthHeader()),
      'Content-Type': 'application/octet-stream'
    }
  })

  if (ret.status === 200) {
    const data = decompressPeople(ret.data) || []
    return data

    // const { data, post, terminate } = useWebWorker(DecompressWorker)

    // try {
    //   post({
    //     buffer: ret.data,
    //     isPeople: true
    //   })
    //   await until(data).changed()
    //   return data.value
    // } finally {
    //   terminate()
    // }
  } else throw new Error(ret.statusText)
}

export async function getPeopleFromLevels({ boardId, levels = 3 }) {
  const ret = await axios.get(`${API_URL}/people/${boardId}/from-levels/${levels}`, {
    responseType: 'arraybuffer',
    headers: {
      ...(await getAuthHeader()),
      'Content-Type': 'application/octet-stream'
    }
  })

  if (ret.status === 200) {
    const data = decompressPeople(ret.data) || []
    return data
  } else throw new Error(ret.statusText)
}

/**
 * This function throttles the requests to the backend to avoid sending same reuqests multiple times. Requests
 * are identified by the cacheKey which is a combination of the boardId, includeApprovedRoles and collapseOn.
 * Case: BaseHierarchy and initial hierarchy call are requesting the same data even though their hooks are initialised
 * with different queryKey
 * @param {*} param0
 * @returns
 */
export async function getHierarchy({ boardId, includeApprovedRoles = false, collapseOn = null }) {
  const cacheKey = `${boardId}-${includeApprovedRoles}-${collapseOn}`

  if (!getHierarchy.pendingPromises) {
    getHierarchy.pendingPromises = new Map()
  }

  if (getHierarchy.pendingPromises.has(cacheKey)) {
    return getHierarchy.pendingPromises.get(cacheKey)
  }

  const promise = (async () => {
    try {
      const headers = {
        ...(await getAuthHeader())
      }

      // Initialize etag for the board
      if (!hierarchyEtagDict[cacheKey]) {
        hierarchyEtagDict[cacheKey] = `${Math.random()}`
      }

      // If a previous etag exists then add it to headers
      // if (hierarchyEtagDict[cacheKey]) {
      //   headers['If-None-Match'] = hierarchyEtagDict[cacheKey]
      // }

      const ret = await axios.post(
        `${API_URL}/people/${boardId}/hierarchy`,
        { data: { includeApprovedRoles, collapseOn } },
        {
          responseType: 'arraybuffer',
          headers,
          validateStatus: function (status) {
            //return true for 2xx and 304
            return status >= 200 && status < 400
          }
        }
      )

      if (ret.status === 200) {
        hierarchyEtagDict[cacheKey] = ret.headers.etag
        const data = decompressPeople(ret.data) || []
        return data
      }
      // else if (ret.status === 304) {
      //   return {
      //     useCache: true
      //   }
      // }
      else throw new Error(ret.statusText)
    } catch (error) {
      getHierarchy.pendingPromises.delete(cacheKey)
      throw error
    }
  })()

  getHierarchy.pendingPromises.set(cacheKey, promise)

  try {
    const result = await promise
    return result
  } finally {
    getHierarchy.pendingPromises.delete(cacheKey)
  }
}

export async function getHierarchyWithFilter({
  boardId,
  filters,
  includeApprovedRoles = false,
  collapseOn = null
}) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/filter-hierarchy`,
    { data: { filters, includeApprovedRoles, collapseOn } },

    {
      responseType: 'arraybuffer',
      headers: {
        ...(await getAuthHeader())
      }
    }
  )

  if (ret.status === 200) {
    const data = decompressPeople(ret.data) || []
    return data
  } else throw new Error(ret.statusText)
}

const hierarchyWithFilterDebounced = useDebounceFn(
  async ({ boardId, filters, includeApprovedRoles = false, collapseOn = null, signal = null }) => {
    const ret = await axios.post(
      `${API_URL}/people/${boardId}/filter-hierarchy`,
      { data: { filters, includeApprovedRoles, collapseOn } },
      {
        responseType: 'arraybuffer',
        headers: {
          ...(await getAuthHeader())
        },
        signal
      }
    )

    if (ret.status === 200) {
      const data = decompressPeople(ret.data) || []
      return data
    } else throw new Error(ret.statusText)
  },
  500
)

export async function getHierarchyWithFilterDebounced({
  boardId,
  filters,
  includeApprovedRoles = false,
  collapseOn = null,
  signal = null
}) {
  const response = await hierarchyWithFilterDebounced({
    boardId,
    filters,
    includeApprovedRoles,
    collapseOn,
    signal
  })

  return response || null
}

/**
 * Retrieves owners by querying for a search value and access level within a specific board.
 *
 * @param {Object} options - The options for the query.
 * @param {string} options.boardId - The ID of the board.
 * @param {string} options.search - The search value.
 * @returns {Promise<Object[]>} - A Promise that resolves to an array of owner objects matching the query.
 */
export async function getOwnersByQuery({ boardId, search, projectId }) {
  const url = new URL(`${API_URL}/people/${boardId}/owners/search`)

  if (search) {
    url.searchParams.set('value', encodeURIComponent(search))
  }

  if (projectId) {
    url.searchParams.set('projectId', projectId)
  }

  const ret = await axios.get(url, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

/**
 * Retrieves owners and their employee profile
 *
 * @param {Object} options - The options for the query.
 * @param {string} options.boardId - The ID of the board.
 * @returns {Promise<Object[]>} - A Promise that resolves to a map of uid -> { user, personId }
 */
export async function getOwnersProfiles({ boardId, uids }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/owners/profiles`,
    {
      data: { uids }
    },
    {
      headers: { ...(await getAuthHeader()) }
    }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

// export async function getPerson({ boardId, personId }) {
//   try {
//     const ret = await axios.get(`${API_URL}/people/${boardId}/${personId}`, {
//       headers: { ...(await getAuthHeader()) }
//     })
//
//     console.log(`getPerson: ${ret.status}`)
//
//     if (ret.status === 200) {
//       return ret.data
//     } else throw new Error(ret.statusText)
//   } catch (error) {
//     console.log(error)
//     return null
//   }
// }

/**
 * In firestore the return value could be a collection of people (emails were not unique)
 * @param boardId
 * @param email
 * @returns {Promise<void>}
 */
export async function getPersonByEmail({ boardId, email }) {
  //GET /people/:boardId/person/:email
  const ret = await axios.get(`${API_URL}/people/${boardId}/person/${email}`, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function getPeopleByEmail({ boardId, email }) {
  //GET /people/:boardId/email/:email
  try {
    const ret = await axios.get(`${API_URL}/people/${boardId}/email/${email}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function exportPpt({
  boardId,
  layers,
  selectedFields,
  filters,
  levelsPerSlide = 2,
  layoutOption = PPTLayoutOptions.HORIZONTAL,
  returnBuffer = false
}) {
  //GET /people/:boardId/export-ppt
  try {
    const ret = await axios.post(
      `${API_URL}/people/${boardId}/export-ppt`,
      { data: { selectedFields, layers, filters, levelsPerSlide, layoutOption } },
      { headers: { ...(await getAuthHeader()) }, responseType: 'blob' }
    )

    if (ret.status !== 200) {
      console.log('Error exporting ppt', ret.statusText)
      return false
    }

    const responseBlob = new Blob([ret.data])
    const text = await responseBlob.text()

    if (text !== 'error') {
      if (returnBuffer) {
        // Return the buffer for zip file creation
        return ret.data
      } else {
        const url = window.URL.createObjectURL(responseBlob)
        const link = document.createElement('a')
        const fileName = `Agentnoon Export ${DateTime.now().toFormat('yyyy-MM-dd')}.pptx`
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return true
      }
    }

    console.error('Generated ppt file not found')
    return false
  } catch (error) {
    console.log(error)
    return false
  }
}

/**
 * Return people matching the emails
 */
export async function getPeopleForEmails({ boardId, emails }) {
  try {
    const ret = await axios.post(
      `${API_URL}/people/${boardId}/email/find-all`,
      { data: { emails } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

// export async function getNewPeople({ boardId, from }) {
//   try {
//     const ret = await axios.get(`${API_URL}/people/${boardId}/from/${from}`, {
//       headers: { ...(await getAuthHeader()) }
//     })
//
//     if (ret.status === 200) {
//       return ret.data
//     }
//     throw new Error(ret.statusText)
//   } catch (error) {
//     console.log(error)
//     return null
//   }
// }

export async function addPerson({ boardId, person }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/create`,
    { data: { person } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 201) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function addPeople({ boardId, people }) {
  try {
    const ret = await axios.post(
      `${API_URL}/people/${boardId}/createMany`,
      { data: { people } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 201) {
      return ret.data // success
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function updatePerson({ boardId, personId, person }) {
  const personData = { ...person }
  personData.removed = Boolean(person.removed)

  const ret = await axios.post(
    `${API_URL}/people/${boardId}/${personId}`,
    { data: { person: personData } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function updatePeople({ boardId, people }) {
  const ret = await axios.patch(
    `${API_URL}/people/${boardId}`,
    { data: { people } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function updateManager({ boardId, personId, newManagerId }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/${personId}/manager`,
    { data: { newManagerId } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function updateManagerBulk({ boardId, peopleIds, newManagerId }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/bulk-manager`,
    { data: { peopleIds, newManagerId } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function deletePerson({ boardId, personId }) {
  const ret = await axios.delete(`${API_URL}/people/${boardId}/${personId}`, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    // Return number of people deleted
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function deletePeople({ boardId, personIds }) {
  const ret = await axios.delete(`${API_URL}/people/${boardId}`, {
    headers: { ...(await getAuthHeader()) },
    data: { personIds }
  })

  if (ret.status === 200) {
    // Return number of people deleted
    return ret.data
  } else throw new Error(ret.statusText)
}

/**
 * Deletes people with following side effects:
 * - managers of removed people will have their subordinates updates
 * - subordinates of removed people will have their managers updated to the next manager in the hierarchy
 */
export async function deletePeopleRetainHierarchy({ boardId, personIds }) {
  const ret = await axios.delete(`${API_URL}/people/${boardId}/retain-hierarchy`, {
    headers: { ...(await getAuthHeader()) },
    data: { personIds }
  })

  if (ret.status === 200) {
    // Return number of people deleted
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function getPersonHistory({ boardId, personId, startIndex, max }) {
  try {
    const ret = await axios.get(
      `${API_URL}/people/${boardId}/${personId}/history/${startIndex}/${max}`,
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

//TODO: move to its own scope (reviews-api)
export async function submitPerson({ boardId, personId }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/${personId}/submit`,
    {},
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function bulkUpdate({ boardId, peopleIds, properties }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/bulk`,
    { data: { peopleIds, properties } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data.length
  } else throw new Error(ret.statusText)
}

export async function bulkStatusUpdate({ boardId, peopleIds, status }) {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/bulk-status`,
    { data: { peopleIds, status } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data.length
  } else throw new Error(ret.statusText)
}

export async function bulkStatusRemove({ boardId, peopleIds, status }) {
  const ret = await axios.delete(`${API_URL}/people/${boardId}/bulk-status`, {
    data: { peopleIds, status },
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return ret.data.length
  } else throw new Error(ret.statusText)
}

const batchedRequestsForBoard = ref({})
const responses = ref({})
const functionForBoard = {}

const getFetchPeopleDebounced = (boardId) =>
  useDebounceFn(async () => {
    const personIds = [...new Set(batchedRequestsForBoard.value[boardId])]
    batchedRequestsForBoard.value[boardId] = []

    if (!personIds || personIds.length === 0) return

    try {
      const ret = await axios.post(
        `${API_URL}/people/${boardId}/get-batch`,
        { data: { personIds } },
        {
          headers: await getAuthHeader()
        }
      )

      if (ret.status === 200) {
        const data = decompressJson(ret.data) || []
        const result = keyBy(data, 'personId') || {}
        personIds.forEach((key) => {
          responses.value[key] = result[key] || null
          // Resolve the promise for each personId
          if (pendingRequests.has(key)) {
            pendingRequests.get(key).resolve(responses.value[key])
            pendingRequests.delete(key)
          }
        })
      } else {
        throw new Error(ret.statusText)
      }
    } catch (error) {
      console.error('Error fetching batched people data:', error)
      personIds.forEach((key) => {
        responses.value[key] = { error: error.message }
        // Reject the promise for each personId
        if (pendingRequests.has(key)) {
          pendingRequests.get(key).reject(error)
          pendingRequests.delete(key)
        }
      })
    }
  }, 500) // Debounce interval of 500ms

async function addToQueue({ boardId, personId }) {
  if (pendingRequests.has(personId)) {
    // If a request is already pending for this personId, return the existing promise
    return pendingRequests.get(personId).promise
  }

  // Create a new promise for this request
  let resolveFn, rejectFn
  const promise = new Promise((resolve, reject) => {
    resolveFn = resolve
    rejectFn = reject
  })

  // Add to pendingRequests
  pendingRequests.set(personId, { promise, resolve: resolveFn, reject: rejectFn })

  if (!batchedRequestsForBoard.value[boardId]) {
    batchedRequestsForBoard.value[boardId] = []
  }

  // Add personId to the queue if it's not already present
  if (!batchedRequestsForBoard.value[boardId].includes(personId)) {
    batchedRequestsForBoard.value[boardId].push(personId)
  }

  if (!functionForBoard[boardId]) {
    functionForBoard[boardId] = getFetchPeopleDebounced(boardId)
  }

  functionForBoard[boardId]()

  try {
    // Await the promise associated with this personId
    const result = await promise
    return result
  } catch (error) {
    console.error('Error in addToQueue:', error)
    return { error: error.message }
  }
}

export const fetchPersonBatched = async ({ boardId, personId }) => {
  if (!boardId || !personId) return {}
  if (personId.includes('-collapsed-')) return {}

  try {
    const result = await addToQueue({ boardId, personId })

    if (result && result.error) {
      throw new Error(result.error)
    }

    return result || {}
  } catch (error) {
    console.error('Error in fetchPersonBatched:', error)
    return { error: error.message }
  }
}

export const filter = async ({ boardId, filters, projectId }) => {
  const url = new URL(`${API_URL}/people/${boardId}/filter`)

  if (projectId) {
    url.searchParams.set('projectId', projectId)
  }

  const ret = await axios.post(
    url,
    { data: { filters } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    const data = decompressJson(ret.data) || []
    return data
  } else throw new Error(ret.statusText)
}

export const search = async ({
  boardId,
  searchQuery,
  state,
  includeEmployeeData = false,
  filters = []
}) => {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/search`,
    { data: { searchQuery, includeEmployeeData, state, filters } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return decompressJson(ret.data) || []
  } else throw new Error(ret.statusText)
}

export const exportCsv = async ({
  boardId,
  boardIds,
  filters = {},
  includeComparison = false,
  selectedFields,
  includeDetachedEmployees = false,
  attestedFields,
  attestedFieldId
}) => {
  const ret = await axios.post(
    `${API_URL}/people/${boardId}/export-csv`,
    {
      data: {
        boardIds,
        filters,
        includeComparison,
        selectedFields,
        includeDetachedEmployees,
        attestedFields,
        attestedFieldId
      }
    },
    { headers: { ...(await getAuthHeader()) }, responseType: 'arraybuffer' }
  )

  if (ret.status === 200) {
    const data = decompressBufferFflate(ret.data) || []
    return data
  } else throw new Error(ret.statusText)
}

// Cleanup function to remove old data and prevent memory leaks
function cleanup() {
  Object.keys(batchedRequestsForBoard.value).forEach((boardId) => {
    if (batchedRequestsForBoard.value[boardId].length === 0) {
      delete batchedRequestsForBoard.value[boardId]
      delete functionForBoard[boardId]
    }
  })
}

// Run cleanup periodically
setInterval(cleanup, 60000) // Cleanup interval of 1 minute
